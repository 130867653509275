<template>
  <file-view-modal v-if='selectedFile' :file='selectedFile' :onClose='closeFile' :downloadedFile='downloadedFile' />
  <bid-file-modal v-if='filesModal.isShow' :files='filesModal.files' :name='filesModal.number'
    :onClose='setFilesModal' />

  <div class="content container-fluid">
    <breadcrumb-header />

    <bid-filters :filters='filters' :resetFilters='resetFilters' />
    <div class="row">
      <div class="col-md-12">
        <core-table :url='url' ref='tableRef' :initialPage='initialPage' :initialData='initialFiltersState'
          :headers='modifiedHeaders' :minHeight='300'>
          <template #item-project_name="{ project }">
            {{ project && project.name }}
          </template>
          <template #item-status="{ status }">
            <div :class='getStatusClass(status)' class='p-2 text-center'>
              {{ status }}
            </div>
          </template>
          <template #item-created_at='{ created_at }'>
            <div>
              {{ fromTimestampToISOString(created_at && created_at * 1000) }}
            </div>
          </template>
          <template #item-actions='{ status, id, files, number }'>
            <div class="text-end">
              <div class="dropdown dropdown-action">
                <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                  aria-expanded="false"><i class="material-icons">more_vert</i></a>
                <div class="dropdown-menu dropdown-menu-right bid__actions-container">
                  <a class="dropdown-item" @click='openBidModal({ files, number })'>
                    <span class='action-icon-container'>
                      <i class="fa fa-external-link"></i>
                    </span>
                    Открыть</a>
                  <a v-if='isShow(BidRequestModeEnum.APPLY, status)' class="dropdown-item"
                    @click='changeBidStatus(id, BidRequestModeEnum.APPLY)'>
                    <span class='action-icon-container'>
                      <img :src='approveIcon' />
                    </span>
                    Принять</a>
                  <a v-if='isShow(BidRequestModeEnum.REJECT, status)' class="dropdown-item"
                    @click='changeBidStatus(id, BidRequestModeEnum.REJECT)'>
                    <span class='action-icon-container'>
                      <img :src='declineIcon' />
                    </span>
                    Отклонить</a>
                  <a v-if='isShow(BidRequestModeEnum.CONFIRM, status)' class="dropdown-item"
                    @click='changeBidStatus(id, BidRequestModeEnum.CONFIRM)'>
                    <span class='action-icon-container'>
                      <img :src='edoIcon' />
                    </span>
                    <span>
                      Подтвердить статус
                      <br>Подписано в ЭДО
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </template>
        </core-table>
      </div>
    </div>

  </div>
</template>
<script setup>
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
import { ref } from 'vue';
import { getStatusClass, getStatusPermission, BidStatusesEnums, BidRequestModeEnum } from '@/entities/bid/index.ts';
import { useUserStore } from '@/stores/user.ts';
import { useFileModal } from '@/widgets/file-view-modal/index.ts';
import request from '@/shared/core-api/index.ts';
import approveIcon from '@/assets/img/icons/approve.svg';
import declineIcon from '@/assets/img/icons/decline.svg';
import edoIcon from '@/assets/img/icons/edo.svg';
import {
  managersHeaders, resourceManagerHeaders, TAM_URL, FIX_PRICE_URL
} from './bid.options.ts'
import { ContractModelEnum } from "@/shared/enums/index.ts";
import { useBidFilters } from '@/widgets/filters/index.ts';
import { computed } from 'vue';
const tableRef = ref();
const updateServerOptions = (data) => {
  tableRef.value.updateServerOptions(data)
}

const props = defineProps({
  mode: String,
})

const {
  filters,
  resetFilters,
  initialFiltersState,
  initialPage
} = useBidFilters(updateServerOptions)

const url = props.mode === ContractModelEnum.TAM ? TAM_URL : FIX_PRICE_URL
const { selectedFile, openFile, closeFile, downloadedFile } = useFileModal();
const user = useUserStore();
const filesModal = ref({
  isShow: false,
  files: [],
  number: ''
});

const setFilesModal = (isShow, files, number) => {
  if (!isShow) {
    filesModal.value = {
      isShow: false,
      files: [],
      number: '',
    }
    return
  }

  filesModal.value = {
    isShow,
    files,
    number
  }
}

const isShow = (buttonType, status) => {
  if (buttonType === BidRequestModeEnum.APPLY) {
    return getStatusPermission(status, [BidStatusesEnums.NEW]) && user.isITManagerRole
      || getStatusPermission(status, [BidStatusesEnums.AGREED_WITH_IT_MANAGER]) && user.isPurchasingManagerRole
      || getStatusPermission(status, [BidStatusesEnums.AWAITING_CONFIRMATION]) && user.isResourseManagerRole
  } else if (buttonType === BidRequestModeEnum.REJECT) {
    return getStatusPermission(status, [BidStatusesEnums.NEW]) && user.isITManagerRole
      || getStatusPermission(status, [BidStatusesEnums.AGREED_WITH_IT_MANAGER]) && user.isPurchasingManagerRole
      || getStatusPermission(status, [BidStatusesEnums.AWAITING_CONFIRMATION]) && user.isResourseManagerRole
  } else if (buttonType === BidRequestModeEnum.CONFIRM) {
    return getStatusPermission(status, [BidStatusesEnums.AGREED_WITH_RESOURSE_MANAGER]) && user.isPurchasingManagerRole
  }
}

const changeBidStatus = async (id, mode) => {
  try {
    await request(`${url}/${id}/${mode}`, {
      method: 'POST',
      auth: true
    });
    updateServerOptions();
  } catch (e) {
    console.error(e);
  }
}

const openBidModal = ({ files, number }) => {
  if (props.mode === ContractModelEnum.FIX_PRICE) {
    setFilesModal(true, files, number);
  } else {
    openFile(files[1], files[0]);
  }
}

const modifiedHeaders = computed(() => {
  const headers = user.isResourseManagerRole ? resourceManagerHeaders : managersHeaders;
  const numberColumn = headers.find(item => item.value === 'number');
  numberColumn.onClick = openBidModal;
  return headers;
})

</script>
