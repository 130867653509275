<template>
  <div v-if='isDataLoading' class='w-100 px-5'>
    <div class='d-flex justify-content-center align-items-center'>
      <img :src='spinnerLogo' class='core-loader' />
    </div>
  </div>
  <div v-else class='w-100 px-5'>
    <filter-component :updateServerOptions='getData'></filter-component>

    <core-form url='/me/technical_fields' :auth='true' :additionalData="additionalData" :onSuccess='onSuccess'
      v-slot='{ isLoading }'>
      <Field name='competence_tf_ids' v-model="result">
        <p v-if='technicalFields.length' class='text-primary cursor-pointer competencies__filter-button'
          @click='selectAll'>
          {{ isSelectedAll ? 'Сбросить' : 'Выбрать все' }}
        </p>
        <perfect-scrollbar>
          <div class='competencies-modal__tf-container'>
            <div class='competencies-modal__list'>
              <div v-for="item in technicalFields" :key="item.id" class='checkbox_container'>
                <label>{{ item.name }} </label>
                <input type="checkbox" class="form-check-input" :checked='selectState[item.id]'
                  @change='(e) => onChange(e, item.id)' />
              </div>
            </div>
          </div>
        </perfect-scrollbar>
        <template v-if='isCustomButtons'>
          <slot name='button-slot' v-bind='{ isLoading, disabled, isDataLoading, submitHandler }'></slot>
        </template>
        <div v-else class='buttons_container'>
          <core-submit-button type='button' :onClick='(e, submit) => submitHandler(submit, disabled || isDataLoading)'
            :changeBeforeSubmit='true' className="btn btn-primary submit-btn" text='Сохранить' :isLoading='isLoading' />
        </div>
      </Field>

    </core-form>
  </div>
</template>
<script setup>
import { additionalData, url } from './select-competencies.options.ts'
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { Field } from "vee-validate";
import FilterComponent from '@/components/Filters/Search';
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { getCompetenciesData } from '../api/getCompetenciesData.ts';
import { getTechnicalFields } from '@/features/select-competencies/index.ts';
import { createSuccessToast } from '../utils/createSuccessToast.ts';
import { createCompetenciesWarning } from '@/entities/competencies/index.ts';
import spinnerLogo from '@/assets/img/icons/spinner.svg';

const props = defineProps({
  onSuccess: Function,
  isCustomButtons: {
    type: Boolean, default: false
  }
});

const technicalFields = ref([]);
const isDataLoading = ref(false)

const initialState = ref([]);
const selectState = reactive({});
const result = ref('');

watch(
  selectState,
  (value) => {
    formatDataForRequest(value)
  },
  { deep: true }
);


onMounted(async () => {
  isDataLoading.value = true;
  await getInitialData();
  await getData();
  isDataLoading.value = false;
})

const getData = async (text) => {
  try {
    const data = await getTechnicalFields({ text, url })
    technicalFields.value = data;
  } catch (e) {

  }
}

const getInitialData = async () => {
  try {
    const data = await getCompetenciesData();
    initialState.value = data;
    data.forEach((field) => {
      selectState[field.technical_field.id] = 1;
    })

  } catch (e) {

  }
}

const disabled = computed(() => {
  if (result.value === '') {
    return true;
  } else {
    return false;
  }
});

const onChange = (event, id) => {
  const checked = event.target.checked;
  selectState[id] = checked ? 1 : 0
}

const isSelectedAll = computed(() => {
  let isNotSelected = true;
  technicalFields.value.forEach(item => {
    if (selectState[item.id] !== 1) {
      isNotSelected = false
      return;
    }
  });

  return isNotSelected
});

const selectAll = () => {
  const isSelected = isSelectedAll.value;
  technicalFields.value.forEach(item => {
    selectState[item.id] = isSelected ? 0 : 1;
  })
}

const formatDataForRequest = (state) => {
  let arr = [];
  for (const [key, value] of Object.entries(state)) {
    if (value) {
      arr.push(key)
    }
  }

  result.value = arr.join(',');
}

const onSuccess = (value) => {
  createSuccessToast();
  props.onSuccess && props.onSuccess(false);
}

const submitHandler = async (submit, isNotAllowed) => {
  if (isNotAllowed) {
    createCompetenciesWarning();
  } else {
    await submit();
  }
}

defineExpose({
  initialState
})

</script>