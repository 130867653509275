<template>
  <!-- Add Employee Modal -->
  <div id="create_application_request" class="modal custom-modal fade show d-block" ref='modalWindowRef'
    @mousedown='localOnClose'>
    <div class="modal-dialog modal-dialog-centered modal-lg create-tam-request-modal">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <h5 class="modal-title text-bold request-modal__title">Сформировать Заявку </h5>
            <p class='gray-text text-center'>Запрос {{ name }}</p>
          </div>
          <button type="button" class="btn-close" @click='onClose(false)'>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if='!approvedWorkers.length'>
            <div class="row margin-15">
              <div class="col">
                <core-select-input name="executor" :data='executorState' :options="allowedCompanies"
                  placeholder="Выберите компанию" displayName='short_name' valueProp='id' />
              </div>
            </div>
            <core-table v-if='executorState.executor && tableIsShow'
              :url='`${url}/${requestId}/responses/accepted?company_id=${executorState.executor}`'
              :headers='mode === FormingRequestMode.TAM_MODE ? tamHeaders : fixPricedHeaders' :showPagination='false'
              :showPaginationCount='false' ref='tableRef' :minHeight='0'>
              <template #item-pay_rate="{ pay_rate }">
                {{ pay_rate && Number(pay_rate).toLocaleString() }} руб
              </template>
              <template #item-cost="{ cost }">
                {{ cost && Number(cost).toLocaleString() }} руб
              </template>
              <template #item-created_at='{ created_at }'>
                <div>
                  {{ fromTimestampToISOString(created_at && created_at * 1000) }}
                </div>
              </template>
              <template #item-actions='{ id }'>
                <core-checkbox-input :name='String(id)' :data='selectedWorkers' :isNumberRequired='true' />
              </template>
            </core-table>
            <div class="buttons_container">
              <button class="btn btn-primary submit-btn" type='button' :disabled='isDisabled'
                @click='approveWorkers'>Далее</button>
              <button class="btn btn-outline-primary submit-btn" type='button' @click='onClose(false)'>Отмена</button>
            </div>
          </div>
          <div v-if='approvedWorkers.length && mode === FormingRequestMode.TAM_MODE'>
            <approve-tam-request :approvedWorkers='approvedWorkers' :onClose='onClose' :id='userId'
              :requestId='requestId' />
          </div>
          <div v-if='approvedWorkers.length && mode === FormingRequestMode.FIX_PRICE_MODE'>
            <approve-fix-price-request :approvedWorkers='approvedWorkers' :onClose='onClose' :id='userId'
              :requestId='requestId' />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</template>
<script setup>
import { tamHeaders, fixPricedHeaders } from './forming-request-modal.options.ts';
import { ref, reactive, watch, computed, onMounted } from 'vue';
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
import { FormingRequestMode } from '@/entities/forming-request-modal/index.ts';
import request from '@/shared/core-api/index.ts';
const tableIsShow = ref(true);
const tableRef = ref();
const modalWindowRef = ref();
const props = defineProps({
  name: String,
  onClose: Function,
  mode: String,
  requestId: String,
  url: String,
});

const executorState = reactive({
  executor: null
});

const userId = computed(() => {
  if (executorState.executor) {
    const company = allowedCompanies.value.find(company => executorState.executor === company.id);
    if (company) {
      return company.user_id;
    }
  }

  return null;
})

// Список утвержденных исполнителей
const selectedWorkers = reactive({});

const isDisabled = computed(() => {
  return Object.values(selectedWorkers).find(worker => worker) ? false : true;
})

watch(
  () => executorState.executor,
  () => {
    resetSelectedWorkers()
  },
  { deep: true }
);

const approvedWorkers = ref([]);
const approveWorkers = () => {
  const workers = [];
  const tableData = tableRef.value.items.data;
  Object.entries(selectedWorkers).forEach(([key, value]) => {
    if (value) {
      const worker = tableData.find(workerData => String(workerData.id) === key && value);
      if (worker) {
        workers.push({ ...worker, checked: 1 })
      }
    }
  })
  approvedWorkers.value = workers
}

const resetSelectedWorkers = () => {
  tableIsShow.value = false;
  setTimeout(() => {
    Object.keys(selectedWorkers).forEach(key => {
      delete selectedWorkers[key];
    });
    tableIsShow.value = true;
  })
}

const localOnClose = (event) => {
  if (event.target === modalWindowRef.value) {
    props.onClose(false, null)
  }
}

const allowedCompanies = ref([])
onMounted(async () => {
  try {
    const { data: responseData } = await request(`${props.mode === FormingRequestMode.TAM_MODE ? 'tam_applications' : 'fp_applications'}/executors/${props.requestId}`, {
      method: 'GET',
      auth: true,
    });

    allowedCompanies.value = responseData;
  } catch (e) {

  }
});
</script>