import { defineStore } from "pinia";

export const useTutorialStore = defineStore("tutorial", {
  persist: true,
  state: () => {
    return {
      isTutorialCompleted: null,
    };
  },
});
