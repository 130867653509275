<template>
  <div class='setup-wrapper'>
    <div class='setup-container'>
      <div class='setup-container__content'>
        <greetings v-if='currentPage === 1' :goAhead='goAhead' />
        <competencies-setup v-if='currentPage === 2' :goAhead='goAhead' :goBack='goBack' />
        <company-setup v-if='currentPage === 3' :goAhead='goAhead' :goBack='goBack' />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const currentPage = ref(1)

const goAhead = () => {
  const page = currentPage.value
  if (page >= 3) {
    return
  }

  currentPage.value = page + 1
}

const goBack = () => {
  const page = currentPage.value
  if (page <= 1) {
    return
  }

  currentPage.value = page - 1
}

</script>
<style>
.ps {
  width: 100%;
}
</style>