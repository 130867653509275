export const radioOptions = [
  {
    label: "С ндс",
    value: 1,
  },
  {
    label: "Без ндс",
    value: 0,
  },
];

export const additionalData = {
  _method: "PUT",
};
