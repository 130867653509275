<template>
  <div class="offcanvas offcanvas-end core-canvas__card show" tabindex="-1">
    <modal-header title='Карточка компании' :onClose='() => onClose(null)' />

    <div class="offcanvas-body">
      <div class="card-body">
        <ul class="nav nav-tabs nav-tabs-bottom nav-justified core-canvas__tabs">
          <li class="nav-item core-canvas__tab">
            <a class="nav-link" ref='activeTab' href="#bottom-justified-tab1" data-bs-toggle="tab">Реквизиты
              компании</a>
          </li>
          <li class="nav-item core-canvas__tab">
            <a class="nav-link" href="#bottom-justified-tab2" data-bs-toggle="tab">Реквизиты банка</a>
          </li>
          <li class="nav-item core-canvas__tab">
            <a class="nav-link" href="#bottom-justified-tab3" data-bs-toggle="tab">Договор</a>
          </li>
        </ul>
        <core-form :url="`/admin/companies/${id}`" :onSuccess="onSuccess" v-slot="{ errors, isLoading, fieldsName }"
          auth='true' :additionalData='additionalData' :initialValidation='schema' :isShowErrorNotification='true'>
          <div class="tab-content">
            <company-requisites-tab id='bottom-justified-tab1' :errors='errors' :data='data'
              :fieldsName='fieldsName'></company-requisites-tab>
            <bank-requisites-tab id='bottom-justified-tab2' :errors='errors' :data='data'
              :fieldsName='fieldsName'></bank-requisites-tab>
            <agreement-tab :data='data' :errors='errors' id="bottom-justified-tab3"
              :fieldsName='fieldsName'></agreement-tab>
          </div>
          <div class='buttons_container'>
            <core-submit-button className="btn btn-primary submit-btn" text='Сохранить' :isLoading='isLoading'
              :disabled='!isChanged || isInitialDataLoading' />
            <button type='button' class="btn btn-outline-primary submit-btn"
              @click='() => onClose(null)'>Отмена</button>
          </div>
        </core-form>
      </div>
    </div>
  </div>

  <div class="offcanvas-backdrop fade show" @click='onClose(null)'></div>
</template>
<script setup>
import { reactive, onMounted, ref } from 'vue'
import { createISOString } from '@/shared/utils/date.ts';
import { schema } from "@/shared/validation/editCompany.ts";
import request from '@/shared/core-api/index.ts';
import { useCompareValues } from '@/shared/utils/index.ts';
const props = defineProps({
  onClose: Function,
  id: String,
})

const activeTab = ref();
const additionalData = {
  _method: 'PUT'
}

const data = reactive({
  'company.type': '',
  'company.short_name': '',
  'company.full_name': '',
  'company.okved': '',
  'company.phone': '',
  maskedCompanyPhone: '',
  'company.tin': '',
  'company.ogrn': '',
  'company.kpp': '',
  'company.okpo': '',

  'company.is_actual_address_equal_legal': 1,
  'company.with_vat': 0,
  'company.addresses[0].index': '',
  'company.addresses[0].country': '',
  'company.addresses[0].city': '',
  'company.addresses[0].street': '',
  'company.addresses[0].house': '',
  'company.addresses[0].building': '',
  'company.addresses[0].apartment': '',

  'company.addresses[1].index': '',
  'company.addresses[1].country': '',
  'company.addresses[1].city': '',
  'company.addresses[1].street': '',
  'company.addresses[1].house': '',
  'company.addresses[1].building': '',
  'company.addresses[1].apartment': '',

  'company.bank_accounts[0].bank_name': '',
  'company.bank_accounts[0].bik': '',
  'company.bank_accounts[0].correspondent_account': '',
  'company.bank_accounts[0].current_account': '',

  'company.contracts[0].number': '',
  'company.contracts[0].start_date': null,
  unmaskedStartDate: '',
  'company.contracts[0].end_date': null,
  unmaskedEndDate: ''
});

const dataFromServer = reactive({});
const { isChanged } = useCompareValues(data, dataFromServer);
const isInitialDataLoading = ref(false);

onMounted(async () => {
  try {
    activeTab.value.click()
    const url = `/admin/companies/${props.id}`
    isInitialDataLoading.value = true;
    const { data: userData } = await request(url, {
      method: 'GET',
      auth: true,
    })

    const {
      type,
      short_name,
      full_name,
      okved,
      phone,
      tin,
      ogrn,
      kpp,
      okpo,
      addresses,
      bank_accounts,
      is_actual_address_equal_legal,
      contracts,
      with_vat
    } = userData;

    data['company.type'] = type;
    data['company.short_name'] = short_name;
    data['company.full_name'] = full_name;
    data['company.okved'] = okved;
    data['company.phone'] = phone;
    data['maskedCompanyPhone'] = phone;
    data['company.tin'] = tin;
    data['company.ogrn'] = ogrn;
    data['company.kpp'] = kpp;
    data['company.okpo'] = okpo;
    data['company.with_vat'] = with_vat || 0;

    data['company.is_actual_address_equal_legal'] = is_actual_address_equal_legal ? 1 : 0;
    data['company.addresses[0].index'] = addresses[0] ? addresses[0].index : '';
    data['company.addresses[0].country'] = addresses[0] ? addresses[0].country : '';
    data['company.addresses[0].city'] = addresses[0] ? addresses[0].city : '';
    data['company.addresses[0].street'] = addresses[0] ? addresses[0].street : '';
    data['company.addresses[0].house'] = addresses[0] ? addresses[0].house : '';
    data['company.addresses[0].building'] = addresses[0] ? addresses[0].building : '';
    data['company.addresses[0].apartment'] = addresses[0] ? addresses[0].apartment : '';

    data['company.addresses[1].index'] = addresses[1] ? addresses[1].index : '';
    data['company.addresses[1].country'] = addresses[1] ? addresses[1].country : '';
    data['company.addresses[1].city'] = addresses[1] ? addresses[1].city : '';
    data['company.addresses[1].street'] = addresses[1] ? addresses[1].street : '';
    data['company.addresses[1].house'] = addresses[1] ? addresses[1].house : '';
    data['company.addresses[1].building'] = addresses[1] ? addresses[1].building : '';
    data['company.addresses[1].apartment'] = addresses[1] ? addresses[1].apartment : '';

    data['company.bank_accounts[0].bank_name'] = bank_accounts[0] ? bank_accounts[0].bank_name : '';
    data['company.bank_accounts[0].bik'] = bank_accounts[0] ? bank_accounts[0].bik : '';
    data['company.bank_accounts[0].correspondent_account'] = bank_accounts[0] ? bank_accounts[0].correspondent_account : '';
    data['company.bank_accounts[0].current_account'] = bank_accounts[0] ? bank_accounts[0].current_account : '';
    data['company.contracts[0].number'] = contracts[0].number
    data['company.contracts[0].start_date'] = contracts[0].start_date ? new Date(contracts[0].start_date * 1000) : null
    data['company.contracts[0].end_date'] = contracts[0].end_date ? new Date(contracts[0].end_date * 1000) : null
    data.unmaskedStartDate = data['company.contracts[0].start_date'] ? createISOString(data['company.contracts[0].start_date']) : null
    data['company.contracts[0].end_date'] = contracts[0].end_date ? new Date(contracts[0].end_date * 1000) : null
    data.unmaskedEndDate = data['company.contracts[0].end_date'] ? createISOString(data['company.contracts[0].end_date']) : null;
    data.contracts = contracts;
    Object.assign(dataFromServer, data);
    isInitialDataLoading.value = false;
  } catch (e) {
    isInitialDataLoading.value = false;
  }
})

const onSuccess = () => {
  props.onClose(null)
}

</script>
