<template>
  <h1>Настройка компании</h1>
  <edit-resource-company :isCustomButtons='true' :onSuccess='onSuccess' :isModeEdit='true'>
    <template v-slot:button-slot='{ isLoading }'>
      <div class='buttons_container'>
        <core-submit-button type='button' :onClick='goBack' className="btn btn-outline-primary submit-btn" text='Назад'
          :isLoading='isLoading' spinnerOptions='text-primary' />
        <core-submit-button className="btn btn-primary submit-btn" text='Завершить' :changeBeforeSubmit='true'
          :isLoading='isLoading' />
      </div>
    </template>
  </edit-resource-company>
</template>
<script setup>
import { setIsTutorialCompleted } from '@/entities/user/index.ts';
import { ref } from 'vue';
import { router } from "@/router";
import { createResourceSetupError } from '@/features/resource-setup/utils/createResourceSetupError.ts';

const isDataReviewing = ref(false);

const props = defineProps({
  goBack: Function
})

const onSuccess = async () => {
  try {
    isDataReviewing.value = true;
    const isTutorialCompleted = await setIsTutorialCompleted();
    if (isTutorialCompleted) {
      router.replace('/dashboard');
    }
    isDataReviewing.value = false;
  } catch (e) {
    isDataReviewing.value = false;
    if (e.data && e.data.errors) {
      const errors = e.data.errors;
      const keys = Object.keys(errors);
      const isCompanyError = keys.find(key => key.includes('company.'))
      if (isCompanyError) {
        createResourceSetupError('Вы не заполнили информацию о компании');
      }
      const isCompetenciesError = keys.find(key => key.includes('competencies'))
      if (isCompetenciesError) {
        createResourceSetupError('Вы не выбрали компетенции');
      }
    }
  }
}

</script>