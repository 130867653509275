import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { router } from "@/router";
import App from "@/App.vue";
import { BootstrapVue3, BToastPlugin } from "bootstrap-vue-3";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import VueSelect from "vue3-select2-component";
import VueApexCharts from "vue3-apexcharts";
import DatePicker from "vue3-datepicker";
import FlagIcon from "vue-flag-icon";
import VueFeather from "vue-feather";
import SummernoteEditor from "vue3-summernote-editor";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import "vue-toast-notification/dist/theme-bootstrap.css";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import CounterCard from "@/entities/profile/ui/counterCard/ui/counter-card.vue";
import ApexChartCard from "@/entities/profile/ui/apexChartCard/ui/apex-chart-card.vue";
// Profile
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "material-icons/css/material-icons.min.css";
import "material-icons/css/material-icons.css";
import "@/assets/plugins/summernote/dist/summernote-lite.min.css";
import "@/assets/plugins/summernote/dist/summernote-lite.min.js";
import "material-icons/iconfont/material-icons.css";
import "material-icons/css/material-icons.min.css";
import "material-icons/css/material-icons.css";
import "pe7-icon/dist/dist/pe-icon-7-stroke.css";
import "typicons.font/src/font/typicons.css";
import "weathericons/css/weather-icons.css";
import "ionicons-npm/css/ionicons.css";
import "@/assets/css/style.css";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import BreadcrumbHeader from "@/components/breadcrumb/BreadcrumbHeader";
import PaginationComponent from "@/components/Pagination";

import "./assets/tailwind.css";
import editEmployer from "./components/modals/edit-employer.vue";
import {
  EditResourceCompany,
  EditResourceCompanyModal,
} from "./features/edit-resource-company/index.ts";
import addEmployer from "./components/modals/add-employer.vue";
import employerRequest from "./components/modals/employer-request.vue";
import openCompany from "./components/modals/open-company.vue";
import addTechArea from "./components/modals/add-entity-area.vue";
import addRequirement from "./components/modals/add-requirement.vue";
import userProfile from "./components/modals/user-profile.vue";
import {
  ProjectModal,
  ProjectInfo,
  CreateProject,
} from "./widgets/project-modal/index.ts";
import {
  CreateRequest,
  CreateFixPriceRequest,
  CreateRequests,
} from "./features/requests/index.ts";
import { RequestSelectModal } from "./shared/request-select-modal/index.ts";
import resourceReleaseCard from "./components/modals/resource-release-card.vue";
import resourceFixedReleaseCard from "./components/modals/resource-fixed-release-card.vue";
import competenciesAlert from "./components/modals/competencies-alert.vue";
import competenciesModal from "./components/modals/competencies-modal.vue";
import coreForm from "./shared/core-form/ui/core-form.vue";
import coreError from "./shared/core-form/ui/core-error-notification/ui/core-error-notification.vue";
import coreNumericInput from "./shared/core-form/ui/core-numeric-input/ui/core-numeric-input.vue";
import corePhoneInput from "./shared/core-form/ui/core-phone-input/ui/core-phone-input.vue";
import coreTextInput from "./shared/core-form/ui/core-text-input/ui/core-text-input.vue";
import coreEmailInput from "./shared/core-form/ui/core-email-input/ui/core-email-input.vue";
import coreSelectInput from "./shared/core-form/ui/core-select-input/ui/core-select-input.vue";
import coreCheckboxInput from "./shared/core-form/ui/core-checkbox-input/ui/core-checkbox-input.vue";
import coreRadioInput from "./shared/core-form/ui/core-radio-input/ui/core-radio-input.vue";
import coreTextAreaInput from "./shared/core-form/ui/core-textarea-input/ui/core-textarea-input.vue";
import coreCalendarInput from "./shared/core-form/ui/core-calendar-input/ui/core-calendar-input.vue";
import corePasswordInput from "./shared/core-form/ui/core-password-input/ui/core-password-input.vue";
import coreSubmitButton from "./shared/core-form/ui/core-submit-button/ui/core-submit-button.vue";
import coreMultipleFilesInput from "./shared/core-form/ui/core-file-input/ui/core-multiple-files-input.vue";
import coreSingleFileInput from "./shared/core-form/ui/core-file-input/ui/core-single-file-input.vue";
import coreTablePagination from "./shared/core-table/ui/core-table-pagination.vue";
import coreTable from "./shared/core-table/ui/core-table.vue";
import successModal from "./components/modals/success-modal.vue";
import dialogModal from "./components/modals/dialog-modal.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import fileModal from "./components/modals/file-view-modal.vue";
import fileIcon from "./shared/file-icon/ui/file-icon.vue";
import file from "./shared/file/ui/file.vue";
import avatarEditor from "./shared/avatar-editor/ui/avatar-editor.vue";
import { ModalHeader, CrossButton } from "./shared/modal/index.ts";
import { RequestsModal } from "@/widgets/requests-modal/index.ts";

// Admin-users page
import personalDataTab from "@/widgets/edit-employer/ui/personalDataTab/ui/personal-data-tab.vue";
import companyRequisitesTab from "@/widgets/edit-employer/ui/companyRequisitesTab/ui/company-requisites-tab.vue";
import bankRequisitesTab from "@/widgets/edit-employer/ui/bankRequisitesTab/ui/bank-requisites-tab.vue";

// Companies page
import agreementTab from "@/widgets/company-card/ui/agreementTab/ui/agreement-tab.vue";
import infoTab from "@/widgets/requests/ui/info-tab/ui/info-tab.vue";
import fixPriceInfoTab from "@/widgets/requests/ui/fix-price-info-tab/ui/fix-price-info-tab.vue";

// Requests page
import resourcesTab from "@/widgets/requests/ui/resources-tab/ui/resources-tab.vue";

// widgets
import { Bid } from "@/widgets/bid/index.ts";
import {
  tmRequestsFilters,
  fixPriceRequestFilters,
  resourceTMRequestsFilters,
  resourceFixPriceRequestFilters,
  adminUsersFilters,
  BidFilters,
  RegisterRequestsFilters,
} from "@/widgets/filters/index.ts";
import {
  requestCardInfo,
  fixPriceRequestCardInfo,
} from "./widgets/requests/index.ts";
import { ResourceSetup } from "@/widgets/resource-setup/index.ts";

// features
import { SelectCompetencies } from "@/features/select-competencies/index.ts";
import { Greetings } from "@/features/greetings/index.ts";
import {
  CompetenciesSetup,
  CompanySetup,
} from "@/features/resource-setup/index.ts";

// entities
import {
  formingRequestModal,
  approveTamRequest,
  approveFixPriceRequest,
} from "@/entities/forming-request-modal/index.ts";
import { BidFileModal } from "@/entities/bid/index.ts";

import "vue3-easy-data-table/dist/style.css";

const app = createApp(App);

// *************** Widgets ***************
app.component("personal-data-tab", personalDataTab);
app.component("company-requisites-tab", companyRequisitesTab);
app.component("bank-requisites-tab", bankRequisitesTab);
app.component("agreement-tab", agreementTab);
app.component("info-tab", infoTab);
app.component("fix-price-info-tab", fixPriceInfoTab);
app.component("resources-tab", resourcesTab);
app.component("bid", Bid);
app.component("bid-file-modal", BidFileModal);
app.component("resource-setup", ResourceSetup);

app.component("EasyDataTable", Vue3EasyDataTable);
app.component("multi-select", Multiselect);
// *************** Modals ***************
app.component("file-view-modal", fileModal);
app.component("edit-employer", editEmployer);
app.component("edit-resource-company-modal", EditResourceCompanyModal);
app.component("edit-resource-company", EditResourceCompany);
app.component("add-employer", addEmployer);
app.component("employer-request", employerRequest);
app.component("open-company", openCompany);
app.component("add-entity-area", addTechArea);
app.component("add-requirement", addRequirement);
app.component("user-profile", userProfile);
app.component("project-modal", ProjectModal);
app.component("project-info", ProjectInfo);
app.component("create-project", CreateProject);
app.component("request-card-info", requestCardInfo);
app.component("create-request", CreateRequest);
app.component("create-fixprice-request", CreateFixPriceRequest);
app.component("create-requests", CreateRequests);
app.component("request-select-modal", RequestSelectModal);
app.component("fix-price-request-card-info", fixPriceRequestCardInfo);
app.component("resource-release-card", resourceReleaseCard);
app.component("resource-fixed-release-card", resourceFixedReleaseCard);
app.component("competencies-alert", competenciesAlert);
app.component("competencies-modal", competenciesModal);
app.component("core-form", coreForm);
app.component("core-phone-input", corePhoneInput);
app.component("core-text-input", coreTextInput);
app.component("core-email-input", coreEmailInput);
app.component("core-select-input", coreSelectInput);
app.component("core-checkbox-input", coreCheckboxInput);
app.component("core-radio-input", coreRadioInput);
app.component("core-numeric-input", coreNumericInput);
app.component("core-textarea-input", coreTextAreaInput);
app.component("core-calendar-input", coreCalendarInput);
app.component("core-password-input", corePasswordInput);
app.component("core-submit-button", coreSubmitButton);
app.component("core-error-notification", coreError);
app.component("core-table", coreTable);
app.component("core-table-pagination", coreTablePagination);
app.component("core-multiple-files-input", coreMultipleFilesInput);
app.component("core-single-file-input", coreSingleFileInput);
app.component("success-modal", successModal);
app.component("dialog-modal", dialogModal);
app.component("requests-modal", RequestsModal);

//filters
app.component("tm-requests-filters", tmRequestsFilters);
app.component("fix-price-requests-filters", fixPriceRequestFilters);
app.component("resource-tm-requests-filters", resourceTMRequestsFilters);
app.component(
  "resource-fix-price-requests-filters",
  resourceFixPriceRequestFilters
);
app.component("admin-users-filters", adminUsersFilters);
app.component("bid-filters", BidFilters);
app.component("register-requests-filters", RegisterRequestsFilters);

// features
app.component("greetings", Greetings);
app.component("competencies-setup", CompetenciesSetup);
app.component("company-setup", CompanySetup);
app.component("select-competencies", SelectCompetencies);

// entities
app.component("forming-request-modal", formingRequestModal);
app.component("approve-tam-request", approveTamRequest);
app.component("approve-fix-price-request", approveFixPriceRequest);
app.component("counter-card", CounterCard);
app.component("apex-chart-card", ApexChartCard);

// *************** Components ***************
app.component("breadcrumb-header", BreadcrumbHeader);
app.component("pagination-component", PaginationComponent);
app.component("file-icon", fileIcon);
app.component("file", file);
app.component("cross-button", CrossButton);
app.component("modal-header", ModalHeader);

app.component("vue-cropper", VueCropper);
app.component("avatar-editor", avatarEditor);

app.component("VueDatePicker", VueDatePicker);
app.component("datepicker", DatePicker);
app.component("vue-select", VueSelect);
app.component(VueFeather.name, VueFeather);
app.component("SummernoteEditor", SummernoteEditor);
app
  .use(VueApexCharts)

  .use(Antd)
  .use(BootstrapVue3)
  .use(BToastPlugin);

const pinia = createPinia();
app.use(VueToast);
pinia.use(piniaPluginPersistedstate);
app.use(FlagIcon);
app.use(pinia);
app.use(router).mount("#app");
