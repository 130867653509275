export const tamHeaders = [
  {
    text: "Исполнитель",
    value: "company_name",
    sortable: false,
    custom: false,
  },
  {
    text: "ФИО",
    value: "candidate_fullname",
    sortable: false,
    custom: false,
    className: "fullname-row",
  },
  { text: "Ставка", value: "pay_rate", sortable: false, custom: true },
  { text: "Дата создания", value: "created_at", sortable: true, custom: true },
  { text: "", value: "actions", sortable: false, custom: true },
];

export const fixPricedHeaders = [
  {
    text: "Исполнитель",
    value: "company_name",
    sortable: false,
    custom: false,
  },
  { text: "Стоимость", value: "cost", sortable: false, custom: true },
  { text: "Дата создания", value: "created_at", sortable: true, custom: true },
  { text: "", value: "actions", sortable: false, custom: true },
];
