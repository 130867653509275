import { useToast } from "vue-toast-notification";
const $toast = useToast();

export const useCreateToast = (number) => {
  $toast.open({
    type: "success",
    message: `
          <div class='error-core-container'>
            <p class="py-0 my-0 w-100">
              Заявка ${number} успешно создана!
            </p>
          </div>
        `,
    duration: 5000,
    position: "bottom-left",
  });
};
