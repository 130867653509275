<template>
  <div>
    <label v-if='showLabel' class="col-form-label">
      {{ label }}
      <span v-if='required' class="text-danger">*</span>
    </label>
    <Field :name="name" v-model="data[name]" :validateOnModelUpdate='false' :validateOnChange='false'
      :validateOnBlur='false' :standalone='isIgnore'>
      <input autocomplete="email" class="form-control" :class="{ 'is-invalid': showError && errors[name] }"
        :placeholder='placeholder || "Введите адрес электронной почты"' v-maska:[emailOptions] data-maska="ABCDE"
        v-model="data[name]" :disabled='disabled' @focus='setFocus(true)' @blur='setFocus(false)'>
    </Field>
    <p v-if='tooltip && isFocusedRef' class='core-input__hint mt-1 mb-0'>Пример: {{ tooltip }}</p>
    <div class="invalid-feedback">{{ showError && errors[name] }}</div>
    <div class="emailshow text-danger" :id="name"></div>
  </div>
</template>
<script setup>
import { Field } from "vee-validate";
import { vMaska } from "maska";
import { emailOptions } from "@/shared/validation/index.ts";
import { addFieldName, removeFieldName } from '@/shared/core-form/utils/addFieldsName.ts';
import { onMounted, onBeforeMount, ref } from 'vue';
const props = defineProps({
  data: Object,
  disabled: Boolean,
  stringOptions: Object,
  placeholder: String,
  showError: Boolean,
  errors: Object,
  name: String,
  fieldsName: Object,
  label: String,
  showLabel: {
    type: Boolean, default: false
  },
  required: {
    type: Boolean, default: false
  },
  isIgnore: {
    type: Boolean, default: false
  },
  tooltip: {
    type: String, default: ''
  }
})

const isFocusedRef = ref()

const setFocus = (isFocused) => {
  isFocusedRef.value = isFocused
}

onMounted(() => {
  if (props.fieldsName && props.label && props.name) {
    addFieldName(props.fieldsName, props.name, props.label)
  }
})

onBeforeMount(() => {
  if (props.fieldsName && props.label && props.name) {
    removeFieldName(props.fieldsName, props.name, props.label)
  }
})
</script>