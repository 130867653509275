<template>
  <div v-if='isInitialLoading' class='d-flex justify-content-center align-items-center'>
    <img :src='spinnerLogo' class='core-loader' />
  </div>
  <core-form v-else url="/me/company" :onSuccess="onSuccessHandler" :initialValidation="resourseManagerCompanySchema"
    v-slot="{ errors, isLoading }" :auth='true' :additionalData='additionalData'>
    <div>
      <h3>Реквизиты компании</h3>
      <div class="row">
        <div class="input-block mb-3">
          <core-select-input name='company.type' label='Организационно-правовая форма' showLabel required
            :options="companyOptions" :data='data' :showError='true' :errors='errors'
            placeholder="Введите организационно-правовую форму" :disabled='!isModeEdit' />
        </div>
      </div>
      <div v-if='data["company.type"]'>
        <div class="row input-container__column_adaptive-md">
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input name="company.full_name" label='Полное наименование компании' showLabel required
                :tooltip='TooltipEnum.COMPANY_FULL_NAME' placeholder="Введите полное наименование компании" :data='data'
                :showError='true' :errors='errors' :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input name="company.short_name" label='Сокращенное наименование компании' showLabel required
                :tooltip='TooltipEnum.COMPANY_SHORT_NAME' placeholder="Введите сокращенное наименование компании"
                :data='data' :showError='true' :errors='errors' :disabled='!isModeEdit' />
            </div>
          </div>
        </div>
        <div class="row input-container__column_adaptive-md">
          <div class="col">
            <div class="input-block mb-3">
              <core-numeric-input :data='data' label='ИНН' showLabel required
                :tooltip='isIPRole ? TooltipEnum.TIN_IP : TooltipEnum.TIN' name="company.tin"
                :placeholder='isIPRole ? TooltipEnum.TIN_IP : TooltipEnum.TIN'
                :mask="isIPRole ? '############' : '##########'" :showError='true' :errors='errors'
                :disabled='!isModeEdit' />
            </div>
          </div>

          <div class="col">
            <div class="input-block mb-3">
              <core-numeric-input :data='data' name="company.ogrn" showLabel required
                :placeholder='isIPRole ? TooltipEnum.OGRN_IP : TooltipEnum.OGRN'
                :mask="isIPRole ? '###############' : '#############'" :showError='true' :errors='errors'
                :label="isIPRole ? 'ОГРН ИП' : 'ОГРН'" :disabled='!isModeEdit'
                :tooltip='isIPRole ? TooltipEnum.OGRN_IP : TooltipEnum.OGRN' />
            </div>
          </div>

          <div v-if="!isIPRole" class="col">
            <div class="input-block mb-3">
              <core-numeric-input label='КПП' showLabel required :tooltip='TooltipEnum.KPP' :data='data'
                name="company.kpp" placeholder='123456789' mask="#########" :showError='true' :errors='errors'
                :disabled='!isModeEdit' />
            </div>
          </div>

          <div class="col">
            <div class="input-block mb-3">
              <core-numeric-input label='ОКПО' showLabel required
                :tooltip='isIPRole ? TooltipEnum.OKPO_IP : TooltipEnum.OKPO' :data='data' name="company.okpo"
                :placeholder='isIPRole ? TooltipEnum.OKPO_IP : TooltipEnum.OKPO'
                :mask="isIPRole ? '##########' : '########'" :showError='true' :errors='errors'
                :disabled='!isModeEdit' />
            </div>
          </div>
        </div>
        <div class='row input-container__column_adaptive-md'>
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input label='ОКВЭД' showLabel required :tooltip='TooltipEnum.OKVED' name="company.okved"
                placeholder='12.34.56' mask='AAAAAAAA' :stringOptions='okvedOptions' :data='data' :showError='true'
                :errors='errors' :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-phone-input label='Номер телефона компании' showLabel required :tooltip='TooltipEnum.PHONE'
                placeholder="+7 (912) 555 22 22" name='company.phone' :data='data' :showError='true' :errors='errors'
                maskedValueFieldName='maskedCompanyPhone' :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col d-flex" v-if="!isIPRole">
            <div class="input-block mb-3">
              <label class='invisible-label'></label>
              <core-radio-input :data='data' name='company.with_vat' :options='radioOptions'
                :initialValue='data.with_vat' className='d-flex' :disabled='!isModeEdit' />
            </div>
          </div>
        </div>
        <h3>Юридический адрес компании</h3>
        <div class="row input-container__column_adaptive-md">
          <div class="col">
            <div class="input-block mb-3">
              <core-numeric-input :data='data' label='Индекс' showLabel required :tooltip='TooltipEnum.INDEX'
                name="company.addresses[0].index" placeholder='123456' mask="######" :showError='true' :errors='errors'
                :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input name="company.addresses[0].country" label='Страна' showLabel required
                :tooltip='TooltipEnum.COUNTRY' placeholder="Введите название страны" :data='data'
                :showError='true' :errors='errors' :stringOptions='stringOptions' mask='A' :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input name="company.addresses[0].city" label='Город, населенный пункт' showLabel required
                :tooltip='TooltipEnum.CITY' placeholder="г. Москва" :data='data' :showError='true' :errors='errors'
                :disabled='!isModeEdit' />
            </div>
          </div>
        </div>
        <div class="row input-container__column_adaptive-md">
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input name="company.addresses[0].street" label='Улица' showLabel required
                :tooltip='TooltipEnum.STREET' placeholder="Ильфата Закирова" :data='data' :showError='true'
                :errors='errors' :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input name="company.addresses[0].house" label='Дом' showLabel required
                :tooltip='TooltipEnum.HOUSE' placeholder="25д" :data='data' :showError='true' :errors='errors'
                :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input name="company.addresses[0].building" label='Корпус' showLabel required
                :tooltip='TooltipEnum.BUILDING' placeholder="6" :data='data' :showError='true' :errors='errors'
                :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input name="company.addresses[0].apartment" label='Квартира, офис' showLabel required
                :tooltip='TooltipEnum.APARTMENT' placeholder="оф. 50" :data='data' :showError='true' :errors='errors'
                :disabled='!isModeEdit' />
            </div>
          </div>
        </div>
        <core-checkbox-input name='company.is_actual_address_equal_legal' :data='data' :isNumberRequired='true'
          :disabled='!isModeEdit' label='Юридический адрес совпадает с фактическим' />
        <div v-if="!data['company.is_actual_address_equal_legal']" class='row mt-3'>
          <h3>Фактический адрес компании</h3>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-numeric-input :data='data' label='Индекс' showLabel required :tooltip='TooltipEnum.INDEX'
                  name="company.addresses[1].index" placeholder='123456' mask="######" :showError='true'
                  :errors='errors' :disabled='!isModeEdit' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-text-input name="company.addresses[1].country" label='Страна' showLabel required
                  :tooltip='TooltipEnum.COUNTRY' placeholder="Введите название страны" :data='data'
                  :showError='true' :errors='errors' :stringOptions='stringOptions' mask='A' :disabled='!isModeEdit' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-text-input name="company.addresses[1].city" label='Город, населенный пункт' showLabel required
                  :tooltip='TooltipEnum.CITY' placeholder="г. Москва" :data='data' :showError='true' :errors='errors'
                  :disabled='!isModeEdit' />
              </div>
            </div>
          </div>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-text-input name="company.addresses[1].street" label='Улица' showLabel required
                  :tooltip='TooltipEnum.STREET' placeholder="Ильфата Закирова" :data='data' :showError='true'
                  :errors='errors' :disabled='!isModeEdit' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-text-input name="company.addresses[1].house" label='Дом' showLabel required
                  :tooltip='TooltipEnum.HOUSE' placeholder="25д" :data='data' :showError='true' :errors='errors'
                  :disabled='!isModeEdit' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-text-input name="company.addresses[1].building" label='Корпус' showLabel required
                  :tooltip='TooltipEnum.BUILDING' placeholder="6" :data='data' :showError='true' :errors='errors'
                  :disabled='!isModeEdit' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-text-input name="company.addresses[1].apartment" label='Квартира, офис' showLabel required
                  :tooltip='TooltipEnum.APARTMENT' placeholder="оф. 50" :data='data' :showError='true' :errors='errors'
                  :disabled='!isModeEdit' />
              </div>
            </div>
          </div>
        </div>
        <h3>Реквизиты банка</h3>
        <div class="row input-container__column_adaptive-xl">
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input name="company.bank_accounts[0].bank_name" label='Полное наименование банка' showLabel
                required :tooltip='TooltipEnum.BANK_NAME' placeholder="Введите полное наименование банка" :data='data'
                :showError='true' :errors='errors' :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-numeric-input :data='data' label='БИК' showLabel required :tooltip='TooltipEnum.BIK'
                name="company.bank_accounts[0].bik" placeholder='044525225' mask="#########" :showError='true'
                :errors='errors' :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-numeric-input :data='data' label='Корреспондентский счет' showLabel required
                :tooltip='TooltipEnum.CORRESPONDENT_ACCOUNT' name="company.bank_accounts[0].correspondent_account"
                placeholder='30101810200000000225' mask="####################" :showError='true' :errors='errors'
                :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-numeric-input :data='data' name="company.bank_accounts[0].current_account" label='Расчетный счет'
                showLabel required :tooltip='TooltipEnum.CURRENT_ACCOUNT' placeholder='40702810900000012345'
                mask="####################" :showError='true' :errors='errors' :disabled='!isModeEdit' />
            </div>
          </div>
        </div>
        <h3>Договор</h3>
        <div class="row input-container__column_adaptive-md">
          <div class="col">
            <div class="input-block mb-3">
              <core-text-input label='Номер договора' showLabel required :tooltip='TooltipEnum.CONTRACT_NUMBER'
                :data='data' name="company.contracts[0].number" mask='AA######' :stringOptions='agreementNumberOptions'
                :showError='true' :errors='errors' :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-calendar-input label='Дата начала' showLabel required inputFormat='dd-MM-yyyy'
                name='company.contracts[0].start_date' :data='data' :showError='true' :errors='errors'
                unmaskedValueFieldName='unmaskedStartDate' :disabled='!isModeEdit' />
            </div>
          </div>
          <div class="col">
            <div class="input-block mb-3">
              <core-calendar-input label='Дата окончания' showLabel required inputFormat='dd-MM-yyyy'
                name='company.contracts[0].end_date' :data='data' :showError='true' :errors='errors'
                unmaskedValueFieldName='unmaskedEndDate' :disabled='!isModeEdit' />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if='isCustomButtons'>
      <slot name='button-slot' v-bind='{ isLoading }'></slot>
    </template>
    <div v-else class='buttons_container'>
      <core-submit-button v-if='!isModeEdit' className="btn btn-primary submit-btn" type='button' text='Редактировать'
        @click='changeMode(true)' />
      <core-submit-button v-if='isModeEdit' className="btn btn-primary submit-btn" text='Сохранить'
        :isLoading='isLoading' />
      <button v-if='isModeEdit' type='button' class="btn btn-outline-primary submit-btn"
        @click='changeMode(false)'>Отмена</button>
    </div>
  </core-form>
</template>

<script setup>
import { stringOptions, agreementNumberOptions, okvedOptions } from "@/shared/validation/index.ts";
import { getEditResourceCompanySchema } from "@/shared/validation/editResourseCompany.ts";
import { reactive, onMounted, computed, ref } from 'vue';
import { getEnums } from '@/shared/core-api/getEnums.ts';
import { COMPANY_TYPE_ENUM } from "@/shared/validation/enums.ts";
import { createISOString } from '@/shared/utils/date.ts';
import {
  radioOptions, additionalData
} from './edit-resource-company.options.ts'
import { getUserDataFromServer } from '@/entities/user/index.ts';
import { TooltipEnum } from '@/shared/core-form/index.ts';
import spinnerLogo from '@/assets/img/icons/spinner.svg';

const isInitialLoading = ref(false)

const props = defineProps({
  onSuccess: Function,
  isCustomButtons: {
    type: Boolean, default: false
  },
  isModeEdit: {
    type: Boolean, default: false
  }
});

const isModeEdit = ref(props.isModeEdit);
const changeMode = (modeType) => {
  if (!modeType) {
    Object.assign(data, meta);
  }
  isModeEdit.value = modeType;
}


const data = reactive({})
const meta = reactive({})
if (typeof data['company.contracts[0].start_date'] === 'string') {
  data['company.contracts[0].start_date'] = new Date(data['company.contracts[0].start_date'])
}

if (typeof data['company.contracts[0].end_date'] === 'string') {
  data['company.contracts[0].end_date'] = new Date(data['company.contracts[0].end_date'])
}

let companyOptions = reactive([])

onMounted(async () => {
  try {
    isInitialLoading.value = true
    const { company_types } = await getEnums(['company_types'], true);

    company_types && company_types.forEach(item => {
      companyOptions.push(item)
    })

    const responseData = await getUserDataFromServer();
    const company = responseData.company;

    if (company) {
      data['company.type'] = company.type;
      data['company.short_name'] = company.short_name;
      data['company.full_name'] = company.full_name;
      data['company.okved'] = company.okved;
      data['company.phone'] = company.phone;
      data['company.tin'] = company.tin;
      data['company.ogrn'] = company.ogrn;
      data['company.kpp'] = company.kpp;
      data['company.okpo'] = company.okpo;
      data['with_vat'] = company.with_vat || 0;

      data['maskedCompanyPhone'] = company.phone;
      data['company.is_actual_address_equal_legal'] = company.is_actual_address_equal_legal ? 1 : 0;
      data['company.addresses[0].index'] = company.addresses[0] ? company.addresses[0].index : '';
      data['company.addresses[0].country'] = company.addresses[0] ? company.addresses[0].country : '';
      data['company.addresses[0].city'] = company.addresses[0] ? company.addresses[0].city : '';
      data['company.addresses[0].street'] = company.addresses[0] ? company.addresses[0].street : '';
      data['company.addresses[0].house'] = company.addresses[0] ? company.addresses[0].house : '';
      data['company.addresses[0].building'] = company.addresses[0] ? company.addresses[0].building : '';
      data['company.addresses[0].apartment'] = company.addresses[0] ? company.addresses[0].apartment : '';

      data['company.addresses[1].index'] = company.addresses[1] ? company.addresses[1].index : '';
      data['company.addresses[1].country'] = company.addresses[1] ? company.addresses[1].country : '';
      data['company.addresses[1].city'] = company.addresses[1] ? company.addresses[1].city : '';
      data['company.addresses[1].street'] = company.addresses[1] ? company.addresses[1].street : '';
      data['company.addresses[1].house'] = company.addresses[1] ? company.addresses[1].house : '';
      data['company.addresses[1].building'] = company.addresses[1] ? company.addresses[1].building : '';
      data['company.addresses[1].apartment'] = company.addresses[1] ? company.addresses[1].apartment : '';

      data['company.bank_accounts[0].bank_name'] = company.bank_accounts[0] ? company.bank_accounts[0].bank_name : '';
      data['company.bank_accounts[0].bik'] = company.bank_accounts[0] ? company.bank_accounts[0].bik : '';
      data['company.bank_accounts[0].correspondent_account'] = company.bank_accounts[0] ? company.bank_accounts[0].correspondent_account : '';
      data['company.bank_accounts[0].current_account'] = company.bank_accounts[0] ? company.bank_accounts[0].current_account : '';
      const contracts = company.contracts
      if (contracts && contracts.length > 0) {
        data['company.contracts[0].number'] = company.contracts[0].number
        if (company.contracts[0].start_date) {
          data['company.contracts[0].start_date'] = new Date(company.contracts[0].start_date * 1000)
          data.unmaskedStartDate = createISOString(new Date(company.contracts[0].start_date * 1000))
        }

        if (company.contracts[0].end_date) {
          data['company.contracts[0].end_date'] = new Date(company.contracts[0].end_date * 1000)
          data.unmaskedEndDate = createISOString(new Date(company.contracts[0].end_date * 1000))
        }
      }

      Object.assign(meta, data);
      isInitialLoading.value = false
    }

  } catch (e) {
    isInitialLoading.value = false
    console.error(e)
  }
})

const isIPRole = computed(() => {
  return data['company.type'] === COMPANY_TYPE_ENUM.IP
})

const resourseManagerCompanySchema = computed(() => {
  return getEditResourceCompanySchema(isIPRole.value)
})

const onSuccessHandler = () => {
  if (props.onSuccess) {
    props.onSuccess()
  } else {
    isModeEdit.value = false;
  }
}
</script>