<template>
  <create-request v-if='isShowRequest.isShow && isShowRequest.type === RequestsEnum.TM' :onClose='showModal' />
  <create-fixprice-request v-if='isShowRequest.isShow && isShowRequest.type === RequestsEnum.FP' :onClose='showModal' />
</template>
<script setup>
import { ref } from 'vue'
import { RequestsEnum } from './create-requests.options.ts';
const props = defineProps({
  onClose: Function
})

const isShowRequest = ref({
  isShow: false,
  type: null,
});

const showModal = ({ isShow, type }) => {
  isShowRequest.value.isShow = isShow;
  isShowRequest.value.type = type;

  if (!isShow) {
    isShowRequest.value.isShow = false;
    isShowRequest.value.type = null;
    props.onClose && props.onClose()
  }
}

defineExpose({
  showModal,
  isShowRequest
})

</script>