<template>
  <div class="main-wrapper">
    <header-layout />
    <sidebar-layout />
    <div class="page-wrapper">
      <router-child class="overflow-hidden" />
    </div>
  </div>
</template>

<script setup>
import RouterChild from '@/components/Child'
import HeaderLayout from '@/components/LayoutComponents/Header'
import SidebarLayout from '@/components/LayoutComponents/Sidebar'
import { onMounted } from 'vue';
import { useUserStore } from '@/stores/user.ts';
import { getUserDataFromServer } from '@/entities/user/index.ts';
const user = useUserStore();

onMounted(async () => {
  try {
    if (user.token) {
      const data = await getUserDataFromServer()

      user.name = data.name;
      user.avatar = data.image_path;
    }
  } catch (e) {

  }
})
</script>
<style lang="scss" scoped>
.main-wrapper {
  max-height: -webkit-fill-available;
  @apply h-screen flex overflow-hidden bg-white sm:bg-gray-100;

  & .page-wrapper {
    @apply w-full overflow-y-auto;
  }

  &>.default-layout-body {
    @apply flex flex-col w-0 flex-1 overflow-hidden;

    &>.default-layout-body-main {
      @apply flex-1 relative overflow-y-auto overflow-x-hidden focus:outline-none h-full;

      &>.default-layout-body-main-inner {
        @apply mx-auto p-0 sm:p-4 lg:p-6 xl:p-8;
      }
    }
  }
}
</style>
