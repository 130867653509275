<template>
  <h1>Настройка компетенций</h1>
  <select-competencies :isCustomButtons='true' :onSuccess='onSuccess'>
    <template v-slot:button-slot='{ isLoading, disabled, isDataLoading, submitHandler }'>
      <div class='buttons_container'>
        <core-submit-button type='button' :onClick='goBack' className="btn btn-outline-primary submit-btn" text='Назад'
          :isLoading='isLoading' spinnerOptions='text-primary' />
        <core-submit-button type='button' :onClick='(e, submit) => submitHandler(submit, disabled || isDataLoading)'
          className="btn btn-primary submit-btn" text='Далее' :changeBeforeSubmit='true' :isLoading='isLoading' />
      </div>
    </template>
  </select-competencies>
</template>
<script setup>

const props = defineProps({
  goAhead: Function,
  goBack: Function
})

const onSuccess = async () => {
  props.goAhead();
}

</script>