import request from "@/shared/core-api/index.ts";
export const getTechnicalFieldsFromServer = async ({ url }) => {
  try {
    const data = await request(url, {
      auth: true,
    });

    return data;
  } catch (e) {}
};
