import * as Yup from "yup";
import { FieldsValidation } from "@/shared/validation/index.ts";

export const resourceSchema = Yup.object().shape({
  responses: Yup.array().of(
    Yup.object().shape({
      pay_rate: Yup.string()
        .nullable()
        .test("len", "Поле не может быть пустым", (val, validationContext) => {
          const file = validationContext.parent.file[0];
          const candidate_fullname =
            validationContext.parent.candidate_fullname;
          if ((file || candidate_fullname) && !val) {
            return false;
          } else {
            return true;
          }
        }),
      candidate_fullname: Yup.string()
        .nullable()
        .test("len", "Поле не может быть пустым", (val, validationContext) => {
          const file = validationContext.parent.file[0];
          const pay_rate = validationContext.parent.pay_rate;
          if ((file || pay_rate) && !val) {
            return false;
          } else {
            return true;
          }
        }),
      file: Yup.string()
        .nullable()
        .test("len", "Поле не может быть пустым", (val, validationContext) => {
          const pay_rate = validationContext.parent.pay_rate;
          const candidate_fullname =
            validationContext.parent.candidate_fullname;
          if ((pay_rate || candidate_fullname) && !val) {
            return false;
          } else {
            return true;
          }
        }),
    })
  ),
});
