export const chartOptions = {
  chart: {
    width: 125,
    type: "donut",
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      donut: {
        labels: {
          show: true,
          name: {
            show: false,
          },
          total: {
            show: true,
            showAlways: true,
            fontSize: "16px",
            fontWeight: 700,
          },
        },
      },
    },
  },
  colors: ["#2FCBFF", "#00BE64", "#7460EE"],
  labels: ["Новые", "Согласовано", "На рассмотрении"],
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: "gradient",
  },
  legend: {
    formatter: function (val, opts) {
      return val + " - " + opts.w.globals.series[opts.seriesIndex];
    },
    show: false,
  },
  title: {
    text: "",
  },
  responsive: [
    {
      breakpoint: 569,
      chart: {
        width: 300,
        type: "donut",
      },
      options: {
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};
