<template>
  <!-- Sidebar -->
  <div class="sidebar core-sidebar" id="sidebar">
    <div class="sidebar-inner slimscroll">
      <perfect-scrollbar class="scroll-area" :settings="settings">
        <div id="sidebar-menu" class="sidebar-menu">
          <ul class="sidebar-vertical">
            <li v-if='user.isAdminRole' class="submenu">
              <a href="#registerRequestsController" data-bs-toggle="collapse" role="button" aria-expanded="false"
                aria-controls="registerRequestsController" :class='{ "noti-dot": counter }'
                class='align-items-center sidebar-collapse'>
                <i class="la la-user-plus"></i>
                <span>Пользователи </span>
                <span class="menu-arrow"></span>
              </a>

              <ul class="collapse" id="registerRequestsController">
                <li :class="{ active: currentPath === 'admin-users' }">
                  <router-link to="/admin-users">
                    <p class='sidebar__text' :class="{ sidebard__text_active: currentPath === 'admin-users' }">
                      Список пользователей
                    </p>
                  </router-link>
                </li>
                <li :class="{ active: currentPath === 'admin-requests' }">
                  <router-link to="/admin-requests">
                    <div class='d-flex align-items-center'>
                      <p class='sidebar__text' :class="{ sidebard__text_active: currentPath === 'admin-requests' }">
                        Запросы на регистрацию</p>
                      <span v-if='counter' class="badge rounded-pill bg-primary float-end">{{ counter }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li v-if='user.isAdminRole' :class="{ active: currentPath === 'admin-companies' }">
              <router-link to="/admin-companies">
                <i class="la la-building"></i> <span>Компании</span>
              </router-link>
            </li>
            <li v-if='user.isAdminRole' :class="{ active: currentPath === 'admin-documents' }">
              <router-link to="/admin-documents">
                <i class="la la-leanpub"></i> <span>Реестр документов</span>
              </router-link>
            </li>
            <li v-if='user.isAdminRole' :class="{ active: currentPath === 'admin-competencies' }">
              <router-link to="/admin-competencies">
                <i class="la la-suitcase"></i>
                <div class='w-100'>
                  <span class='sidebar-link'>Справочник компетенций</span>
                </div>
              </router-link>
            </li>
            <li v-if='user.isPurchasingManagerRole || user.isITManagerRole || user.isResourseManagerRole'
              :class="{ active: currentPath === 'dashboard' }">
              <router-link to="/dashboard">
                <i class="la la-university"></i> <span>Главная</span>
              </router-link>
            </li>
            <li v-if='user.isPurchasingManagerRole || user.isITManagerRole || user.isResourseManagerRole'
              :class="{ active: currentPath === 'projects' }">
              <router-link to="/projects">
                <i class="la la-rocket"></i> <span>Мои проекты</span>
              </router-link>
            </li>

            <li v-if='user.isPurchasingManagerRole || user.isITManagerRole' class="submenu">
              <a href="#itReq" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="itReq"
                class='sidebar-collapse'
                :class='{ "noti-dot": counter && (counter.new_tam_response_count || counter.new_fp_response_count) }'>
                <i class="la la-share-alt"></i>
                <span>Запросы</span>
                <span class="menu-arrow"></span>
              </a>

              <ul class="collapse" id="itReq">
                <li :class="{ active: currentPath == 'itmanager-resources-request' }">
                  <router-link to="/itmanager-resources-request">
                    <div class='d-flex align-items-center'>
                      <p class='sidebar__text'
                        :class="{ sidebard__text_active: currentPath === 'itmanager-resources-request' }">
                        Запросы на ресурсы Т&M
                      </p>
                      <span v-if='counter && counter.new_tam_response_count'
                        class="badge rounded-pill bg-primary float-end">
                        {{ counter.new_tam_response_count }}
                      </span>
                    </div>
                  </router-link>
                </li>
                <li :class="{ active: currentPath == 'itmanager-fixprice-request' }">
                  <router-link to="/itmanager-fixprice-request">
                    <div class='d-flex align-items-center'>
                      <p class='sidebar__text'
                        :class="{ sidebard__text_active: currentPath === 'itmanager-fixprice-request' }">
                        Модель Fix Priced
                      </p>
                      <span v-if='counter && counter.new_fp_response_count'
                        class="badge rounded-pill bg-primary float-end">
                        {{ counter.new_fp_response_count }}
                      </span>
                    </div>

                  </router-link>
                </li>
              </ul>
            </li>

            <li v-if='user.isResourseManagerRole' class="submenu">
              <a href="#resReq" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="resReq"
                class='sidebar-collapse'>
                <i class="la la-share-alt"></i>
                <span>Запросы</span>
                <span class="menu-arrow"></span>
              </a>

              <ul class="collapse" id="resReq">
                <li :class="{ active: currentPath == 'resource-resources-request' }">
                  <router-link to="/resource-resources-request">
                    <p class='sidebar__text'
                      :class="{ sidebard__text_active: currentPath === 'resource-resources-request' }">
                      Запросы на ресурсы Т&M
                    </p>
                  </router-link>
                </li>
                <li :class="{ active: currentPath == 'resource-fixprice-request' }">
                  <router-link to="/resource-fixprice-request">
                    <p class='sidebar__text'
                      :class="{ sidebard__text_active: currentPath === 'resource-fixprice-request' }">
                      Модель Fix Priced
                    </p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li v-if='!user.isAdminRole && user.role' class="submenu">
              <a href="#resBid" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="resBid"
                class='sidebar-collapse'>
                <i class="la la-braille"></i>
                <span>Заявки</span>
                <span class="menu-arrow"></span>
              </a>

              <ul class="collapse" id="resBid">
                <li :class="{ active: currentPath == 'bid' }">
                  <router-link to="/bid">
                    <p class='sidebar__text' :class="{ sidebard__text_active: currentPath === 'bid' }">
                      Заявки на ресурсы Т&M
                    </p>
                  </router-link>
                </li>
                <li :class="{ active: currentPath == 'fixprice-bid' }">
                  <router-link to="/fixprice-bid">
                    <p class='sidebar__text' :class="{ sidebard__text_active: currentPath === 'fixprice-bid' }">
                      Заявки на Fix Priced
                    </p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li v-if='user.isResourseManagerRole' :class="{ active: currentPath === 'resource-competencies' }">
              <router-link to="/resource-competencies">
                <i class="la la-archive"></i> <span>Компетенции</span>
              </router-link>
            </li>

            <li v-if='user.isPurchasingManagerRole || user.isITManagerRole' class="submenu">
              <a href="#sidebarRefs" data-bs-toggle="collapse" role="button" aria-expanded="false"
                aria-controls="sidebarRefs" class='sidebar-collapse'>
                <i class="la la-archive"></i>
                <span>Справочники</span>
                <span class="menu-arrow"></span>
              </a>
              <ul class="collapse" id="sidebarRefs">
                <li :class="{ active: currentPath == 'partners' }">
                  <router-link to="/partners">
                    <p class='sidebar__text' :class="{ sidebard__text_active: currentPath === 'partners' }">
                      Партнеры
                    </p>
                  </router-link>
                </li>
                <li :class="{ active: currentPath == 'admin-competencies' }">
                  <router-link to="/admin-competencies">
                    <p class='sidebar__text' :class="{ sidebard__text_active: currentPath === 'admin-competencies' }">
                      Компетенций
                    </p>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- /Sidebar -->
</template>
<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useUserStore } from "@/stores/user.ts";
import { useCounter } from '@/features/request-counters/index.ts';
const user = useUserStore();
const { timer, counter, getCounter } = useCounter()
onMounted(() => {
  getCounter(0);
})

onUnmounted(() => {
  timer && clearTimeout(timer)
})

</script>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
    };
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
