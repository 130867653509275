<template>
  <div class="custom-form-check" :class='className'>
    <Field :name='name' v-model="data[name]">
      <div v-for='item in options' class='radio-button__button-container'>
        <label class="form-check-label radio-button__label" :for="item.label">{{ item.label }}</label>
        <input type="checkbox" class="form-check-input" :id="item.label" :checked='data[name] === item.value'
          :disabled='disabled' @change='(e) => onChange(item.value, e)' />
      </div>
    </Field>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import { Field } from "vee-validate";
const props = defineProps({
  options: Array,
  initialValue: {
    type: [String, Number],
    required: true,
  },
  data: Object,
  name: String,
  className: String,
  disabled: {
    type: Boolean, default: false
  }
})

onMounted(() => {
  props.data[props.name] = props.initialValue;
})

const onChange = (value, e) => {
  props.data[props.name] = value;
  e.target.checked = true;
}

</script>