<template>
  <div class="offcanvas offcanvas-end competencies-container show" tabindex="-1">
    <modal-header title='Техническая область' :isShowSeparator='true' :onClose='onCloseHandler' />
    <div class="offcanvas-body competencies-modal__body">
      <div class="card-body">
        <select-competencies ref='competenciesRef' :onSuccess='onClose' />
      </div>
    </div>
  </div>
  <div class="offcanvas-backdrop fade show" @mousedown='onCloseHandler'></div>
</template>
<script setup>
import { createCompetenciesWarning } from '@/entities/competencies/index.ts';
import { ref } from 'vue';

const competenciesRef = ref()
const props = defineProps({
  onClose: Function,
});

// Если нет ни одной начальной компетенции - то запретить пользователю закрывать модальное окно.
const onCloseHandler = () => {
  const value = competenciesRef.value.initialState;
  if (value.length < 1) {
    createCompetenciesWarning();
    return
  }

  props.onClose(false);
}


</script>
