import request from "@/shared/core-api/index.ts";

export const getProjectServerData = async (id) => {
  try {
    const { data } = await request(`/projects/${id}`, {
      auth: true,
    });

    return data;
  } catch (e) {
    throw e;
  }
};
