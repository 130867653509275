<template>
  <div class="row filter-row table-filter-row">
    <div class="col-sm flex-grow-1">
      <div class="input-block mb-3 form-focus">
        <div class="input-group">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control floating py-0" v-model="filters.search_query" placeholder='Поиск' />
        </div>
      </div>
    </div>

    <div class="col-sm table-filter-row__fix-price-row">
      <div class="input-block mb-2 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" :disabled='isDisabledStatus' name='status'
          :options="statusOptions" placeholder="По статусу" />
      </div>
    </div>

    <div class="col-sm table-filter-row__fix-price-row">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='project_id' :options="projectOptions"
          placeholder="По проекту" valueProp='id' displayName='title' />
      </div>
    </div>

    <div class="col-sm table-filter-row__fix-price-row">
      <button class="btn btn-success w-100 text-capitalize" @click="resetFilters">
        Сбросить фильтры
      </button>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue';
import { getEnums } from '@/shared/core-api/getEnums.ts';
import { formatStringArrForCoreSelect } from '@/shared/core-form/index.ts';
import { getNotArchivedProjects } from "@/shared/projects/index.ts";

const props = defineProps({
  mode: Number | String,
  filters: Object,
  resetFilters: Function
});

const statusOptions = ref([]);
const projectOptions = ref([]);

const isDisabledStatus = computed(() => {
  return props.mode === 1
})

onMounted(async () => {
  try {
    const { request_statuses } = await getEnums(['request_statuses'], {
      auth: true
    })

    statusOptions.value = formatStringArrForCoreSelect(request_statuses);

    const responseData = await getNotArchivedProjects();

    projectOptions.value = responseData;
  } catch (e) {

  }
});

</script>
