<template>
  <div class='table-container'>
    <div v-if='showPaginationCount' class='core-table__placeholder-container' :class='paginationCounterStyle'>
      <div>
        Показывать <select class="select-items" @change="updateRowsPerPageSelect">
          <option v-for="item in rowsPerPageOptions" :key="item" :selected="item === rowsPerPageActiveOption"
            :value="item">
            {{ item }}
          </option>
        </select> записи
      </div>
      <slot name='placeholder'></slot>
    </div>
    <EasyDataTable v-model:server-options="serverOptions" :headers="headers" :items="[...items.data]"
      :server-items-length="serverItemsLength" :loading="loading" :table-min-height='minHeight'
      :table-class-name="tableClassName ? `customize-table ${tableClassName}` : 'customize-table'" alternating
      hide-footer ref="dataTable" :rows-items='rowItemsOptions' @update-sort="updateSort" :hideHeader='!showHeader'
      empty-message='Данные отсутствуют'>

      <template #header="header">
        <div class='table-header' :class='headerClassName'>
          {{ header.text }}
        </div>
      </template>

      <template v-if='customEmptySlot' #empty-message>
        <slot name='empty-slot'></slot>
      </template>

      <template v-for='header in headers' #[`item-${header.value}`]="values">
        <div class="core-table-cell" :class='tableCellClassName, header.className, header.onClick && "cursor-pointer"'
          :onClick='header.onClick ? () => {
            header.onClick(values)
          } : undefined'>
          <slot v-if='header.custom' :name='slotName(header)' v-bind='values'></slot>
          <div v-else class='h-100'>{{ values[header.value] }}</div>
        </div>
      </template>
    </EasyDataTable>
    <div v-if='showPagination' class="core-table__pagination-container" :class='paginationStyle'>
      <div class="customize-index">
        Показаны с {{ currentPageFirstIndex }} по {{ currentPageLastIndex > currentPageFirstIndex ? currentPageLastIndex
          : currentPageFirstIndex + serverOptions.rowsPerPage - 1 }} из {{ clientItemsLength }} записей </div>
      <core-table-pagination :current-pagination-number="currentPaginationNumber" :isFirstPage='isFirstPage'
        :isLastPage='isLastPage' :max-pagination-number="maxPaginationNumber" @update-page="updatePage"
        :prevPage='prevPage' :nextPage='nextPage' />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, reactive } from "vue";
import { usePagination, useRowsPerPage } from "use-vue3-easy-data-table";
import request from '@/shared/core-api/index.ts';
import { useRoute } from 'vue-router';
import { addParams } from '@/shared/utils/updateQueryParams.ts';
const router = useRoute();
const props = defineProps({
  url: String,
  headers: Object,
  showHeader: { type: Boolean, default: true },
  showPagination: { type: Boolean, default: true },
  showPaginationCount: { type: Boolean, default: true },
  isOfflineMode: {
    type: Boolean, default: false
  },
  items: {
    type: Array, default: []
  },
  className: String,
  headerClassName: {
    type: String, default: ''
  },
  formatServerData: Function,
  customEmptySlot: {
    type: Boolean, default: false
  },
  paginationStyle: {
    type: String, default: ''
  },
  paginationCounterStyle: {
    type: String, default: ''
  },
  afterLoadhandler: Function,
  initialData: Object,
  tableClassName: {
    type: String, default: ''
  },
  tableCellClassName: {
    type: String, default: ''
  },
  minHeight: {
    type: Number, default: 180
  },
  initialPage: {
    type: Number, default: 1
  },
  isSaveFilters: {
    type: Boolean, default: false
  }
})

const rowItemsOptions = [10, 25, 50, 100];
const slotName = (header) => {
  return `item-${header.value}`
}

const skipServerUpdate = ref(false);
const updateSort = (sortOptions) => {
  if (sortOptions.sortType) {
    serverOptions.value = { ...serverOptions.value, ...sortOptions };
  } else {
    serverOptions.value = { ...serverOptions.value, sortType: null, sortBy: null };
  }
};

const items = reactive({
  data: []
})

const dataTable = ref();

const {
  currentPageFirstIndex,
  currentPageLastIndex,
  clientItemsLength,
  maxPaginationNumber,
  currentPaginationNumber,
  isFirstPage,
  isLastPage,
  nextPage,
  prevPage,
  updatePage,
} = usePagination(dataTable);

const {
  rowsPerPageOptions,
  rowsPerPageActiveOption,
  updateRowsPerPageActiveOption,
} = useRowsPerPage(dataTable);

const updateRowsPerPageSelect = (e) => {
  updateRowsPerPageActiveOption(Number(e.target.value));
};

const serverItems = ref([]);
const serverItemsLength = ref(0);
const metaData = ref({});
const serverOptions = ref({
  page: props.initialPage,
  rowsPerPage: 10,
});
const filtersOptions = ref(props.initialData ? { ...props.initialData } : {});

const updateServerOptions = (data, resetPageCounter) => {
  if (resetPageCounter) {
    skipServerUpdate.value = true;
    serverOptions.value.page = 1;
  }
  filtersOptions.value = { ...filtersOptions.value, ...data };
}

const createParamsBody = (body) => {
  const params = {}
  for (const [key, value] of Object.entries(body)) {
    if (value || value === 0) {
      params[key] = value;
    }
  }

  return params;
}

const loading = ref(false);
const loadFromServer = async () => {
  if (props.isOfflineMode) {
    items.data = props.items;
    return;
  }
  loading.value = true;
  const body = {
    per_page: serverOptions.value.rowsPerPage,
    page: serverOptions.value.page,
    sort_column: serverOptions.value.sortBy,
    sort_order: serverOptions.value.sortType,
    ...filtersOptions.value
  }

  const queryParamsBody = createParamsBody(body);

  try {
    const response = await request(props.url, {
      method: 'GET',
      auth: true,
      queryParamsBody,
    })

    if (props.formatServerData) {
      response.data = props.formatServerData(response.data);
    }
    items.data = response.data
    serverItems.value = items;
    metaData.value = response.meta;
    serverItemsLength.value = response.meta.total;
    loading.value = false;
    props.afterLoadhandler && props.afterLoadhandler(response.data)
  } catch (e) {
    items.data = [];
    loading.value = false;
  }
};

const setIsLoading = (isLoading) => {
  loading.value = isLoading;
}

defineExpose({
  setIsLoading,
  updateServerOptions,
  loadFromServer,
  filtersOptions: filtersOptions.value,
  metaData,
  items,
})

loadFromServer();

// Объединение внутренних и внешних фильтров
watch(
  [filtersOptions, serverOptions],
  () => {
    loadFromServer();
  },
  { deep: true }
);

watch(
  () => serverOptions.value.page,
  (value) => {
    if (props.isSaveFilters) {
      const params = router.query
      addParams({ page: value }, params);
    }
  },
  { deep: true }
);

</script>
<style>
.customize-table {
  --easy-table-border: 0px solid #445269;
  --easy-table-row-border: 1px solid #e2e5e8;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: black;
  --easy-table-header-background-color: #fff;
  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: black;
  --easy-table-body-even-row-background-color: #fff;

  --easy-table-body-row-font-color: black;
  --easy-table-body-row-background-color: #f2f2f2;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 18px;

  --easy-table-body-row-hover-font-color: black;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 0px 0px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #c0c7d2;
  --easy-table-scrollbar-color: #c0c7d2;
  --easy-table-scrollbar-thumb-color: #e2e5e8;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}
</style>