<template>
  <div>
    <label v-if='showLabel' class="col-form-label">
      {{ label }}
      <span v-if='required' class="text-danger">*</span>
    </label>
    <p v-if='hint && isFocusedRef' class='core-input__hint mb-0'>{{ hint }}</p>
    <Field :name="name" class="form-control" v-model='data[name]' :validateOnModelUpdate='false'
      :validateOnChange='false' :validateOnBlur='false' ref='inputRef'>
      <div style='position: relative;'>
        <b-form-textarea v-model="data[name]" :rows="rows" :max-rows="['max-rows']" :disabled='disabled'
          :maxlength="maxLength" @focus='setFocus(true)' @blur='setFocus(false)'>

        </b-form-textarea>
        <div v-if='maxLength' class='core-text-area__counter'> {{ data[name] ? data[name].length
          : 0 }}/{{ maxLength }}</div>
      </div>
    </Field>
    <div class="emailshow text-danger" :id="name">{{ showError ? errors[name] : '' }}</div>
  </div>
</template>
<script setup>
import { Field } from "vee-validate";
import { ref } from 'vue';
const isFocusedRef = ref()

const setFocus = (isFocused) => {
  isFocusedRef.value = isFocused
}

defineProps({
  data: Object,
  disabled: Boolean,
  placeholder: String,
  showError: {
    type: Boolean, default: false
  },
  errors: Object,
  name: String,
  maxLength: Number,
  rows: {
    type: String, default: "10"
  },
  'max-rows': {
    type: String, default: "10"
  },
  label: String,
  showLabel: {
    type: Boolean, default: false
  },
  required: {
    type: Boolean, default: false
  },
  isShowTooltip: {
    type: Boolean, default: true
  },
  hint: {
    type: String, default: ''
  }
})
</script>
<style>
.multiselect-option {
  white-space: pre-wrap;
}
</style>