import request from "@/shared/core-api/index.ts";
import { queryInterceptor } from "@/shared/utils/queryInterceptor.ts";
import { router } from "@/router";
import { useTutorialStore } from "@/stores/tutorial.ts";

const isCheckCompetenciesForResourseManager = async (queryState) => {
  try {
    if (queryState.isLoading) {
      return;
    }
    queryState.isLoading = true;
    const { data } = await request("/me/technical_fields", {
      auth: true,
    });

    queryState.isLoading = false;
    if (data.length) {
      return true;
    } else {
      router.push("/resource-competencies");
    }
  } catch (e) {
    console.error(e);
    queryState.isLoading = false;
  }
};

export const wrappedIsCheckCompetenciesForResourseManager = queryInterceptor(
  isCheckCompetenciesForResourseManager
);

export const checkIsTutorialSetupCompleted = (to, next) => {
  const { isTutorialCompleted } = useTutorialStore();
  if (to.path === "/account-setup") {
    return true;
  }

  if (isTutorialCompleted) {
    return true;
  }
  if (isTutorialCompleted === 0 || isTutorialCompleted === "0") {
    next("/account-setup");
  }

  return false;
};
