import { useToast } from "vue-toast-notification";

export const createCompetenciesWarning = () => {
  const $toast = useToast();
  $toast.open({
    type: "warning",
    message: `
          <div class='error-core-container'>
            <p class="py-0 my-0 w-100">
              Вы не можете продолжить работу. Выберите Техническую область
            </p>
          </div>
        `,
    duration: 5000,
    position: "bottom-left",
  });
};
