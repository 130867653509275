<template>
  <div id="user_profile" class="modal custom-modal d-block" role="dialog" ref='modalWindowRef'
    @mousedown='localOnClose'>
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Настройки фотографии профиля</h5>
          <button type="button" class="btn-close" @click='onClose(false)'>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <core-form url='/me/user_photo' :auth='true' v-slot='{ errors, isLoading }' :additionalData="additionalData"
            :onSuccess='onSuccess'>
            <div class='d-flex flex-column gap-4'>
              <div
                style='max-height: 70vh; width: 100%; height: 60vh; display: flex; align-items: center; justify-content: center;'>
                <vue-cropper :class='!imageUrl.url && "d-none"' ref="cropper" :src="imageUrl.url" :imgStyle="{
                  'max-height': '60vh'
                }" :containerStyle="{ 'max-height': '60vh' }" :background='false' :viewMode="2" fillColor='#fff'
                  :aspectRatio='1'></vue-cropper>
                <h3 v-if='!imageUrl.url'>Загрузите изображение</h3>
              </div>
            </div>

            <Field name="photo" ref='fieldRef' class=''>
              <input type='file' id='file' @change='filesOnChange' style='display: none;' ref='inputRef'
                :class="{ 'is-invalid': errors.photo }" accept="image/png, image/jpeg, image/WebP" />
              <div class='w-100 d-flex justify-content-center mt-3'>
                <label for="file" style='width: auto;'>
                  <div class='avatar__upload-button loader-container' :disabled='isImgLoading'>
                    <span :class='isImgLoading ? "opacity-0" : "opacity-100"'>Выберите
                      изображение</span>
                    <div v-if='isImgLoading' class="spinner-border spinner-border-sm loader-cointainer__loader"
                      role="status"></div>
                  </div>

                </label>
              </div>
              <div class="invalid-feedback w-100 text-center mt-2">{{ errors.photo }}</div>
              <div class='w-100 d-flex flex-column align-items-center mt-2'>
                <p class="text-gray-50 text-xs mb-1">Размер изображения не должен превышать 5 MB</p>
                <p class="text-gray-50 text-xs mb-0">Поддерживаемый формат: JPEG, PNG, WebP</p>
              </div>
            </Field>

            <div class='buttons_container'>
              <core-submit-button className="btn btn-primary submit-btn" :onClick='cropImage' :changeBeforeSubmit='true'
                text='Сохранить' type='button' :isLoading='isCutting || isLoading' />
              <button class="btn btn-outline-primary submit-btn" type='button' @click='onClose(false)'>Отмена</button>
            </div>
          </core-form>
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas-backdrop fade show"></div>
</template>

<script setup>
import { ref } from 'vue';
import { Field } from "vee-validate";
import { useUserStore } from '@/stores/user.ts';
import { useToast } from 'vue-toast-notification';
const fieldRef = ref()
const inputRef = ref();
const modalWindowRef = ref();
const isImgLoading = ref(false)
const isCutting = ref(false)
const cropper = ref();
const user = useUserStore();
const $toast = useToast();

const createWarning = (text) => {
  $toast.clear();
  $toast.open({
    type: 'error',
    message: `
          <div class='error-core-container'>
            <p class="py-0 my-0 w-100">
              ${text}
            </p>
          </div>
        `,
    duration: 5000,
    position: 'bottom-left'
  });
}

const additionalData = {
  _method: 'PUT'
}

const props = defineProps({
  onClose: Function
})

const imageUrl = ref({
  url: null,
  type: null
})

const filesOnChange = (e) => {
  const file = e.target.files[0];
  if (!file) {
    return;
  }

  if (file.type.indexOf('image/') === -1) {
    createWarning('Неподдерживаемый формат файла');
    inputRef.value.value = null;
    isImgLoading.value = false;
    return;
  }

  if (file.size > 5242880) {
    createWarning('Превышен максимально допустимый размер файла');
    inputRef.value.value = null;
    isImgLoading.value = false;
    return;
  };
  isImgLoading.value = true

  if (typeof FileReader === 'function') {
    const reader = new FileReader();
    reader.onload = (event) => {
      imageUrl.value = {
        url: event.target.result,
        type: file.type
      }

      cropper.value.replace(event.target.result);
      isImgLoading.value = false
    };

    reader.readAsDataURL(file);
  } else { }
}

const cropImage = async (e, submit) => {
  isCutting.value = true;
  cropper.value.getCroppedCanvas().toBlob((blob) => {
    // v-model внутри Field не обновляет blob объект
    // Ставим значение через ref
    fieldRef.value.handleChange(blob, false);
    submit();
    isCutting.value = false;
  }, imageUrl.value.type);
}

const localOnClose = (event) => {
  if (event.target === modalWindowRef.value) {
    props.onClose(false)
  }
}

const onSuccess = (data) => {
  user.avatar = data.image_path;
  props.onClose(false, data.image_path)
}
</script>

<style>
.cropper-modal {
  background-color: #fff;
}
</style>