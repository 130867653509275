import request from "@/shared/core-api/index.ts";

export const setIsTutorialCompletedOnServer = async () => {
  try {
    const { data } = await request("/me/tutorial_status", {
      method: "POST",
      auth: true,
    });

    return data;
  } catch (e) {
    throw e;
  }
};
