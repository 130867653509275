export const dialogOptions = {
  title: "Сбросить данные",
  body: "Вы уверены, что хотите закрыть окно создания нового проекта? При закрытии введенные данные не сохранятся.",
  onSuccessButton: "Продолжить",
  onCancelButton: "Отмена",
};

export const ProjectModalModeEnum = {
  READ: "read",
  EDIT: "edit",
  NEW: "new",
};
