import { setIsTutorialCompletedOnServer } from "@/entities/user/index.ts";
import { useTutorialStore } from "@/stores/tutorial.ts";

export const setIsTutorialCompleted = async () => {
  try {
    const response = await setIsTutorialCompletedOnServer();
    const tutorial = useTutorialStore();
    tutorial.isTutorialCompleted = response.is_tutorial_complete;
    return response.is_tutorial_complete;
  } catch (e) {
    throw e;
  }
};
