import { defineStore } from "pinia";

export const useAddEmployerStore = defineStore("addEmployer", {
  persist: true,
  state: () => {
    return {
      "user.name": "",
      "user.role": "",
      "user.email": "",
      "user.phone": "",
      maskedUserPhone: "",

      "company.type": "",
      "company.full_name": "",
      "company.short_name": "",
      "company.okved": "",
      "company.phone": "",
      maskedCompanyPhone: "",
      "company.tin": "",
      "company.ogrn": "",
      "company.kpp": "",
      "company.okpo": "",
      "company.with_vat": 0,

      "company.is_actual_address_equal_legal": 1,
      "company.addresses[0].index": "",
      "company.addresses[0].country": "",
      "company.addresses[0].city": "",
      "company.addresses[0].street": "",
      "company.addresses[0].house": "",
      "company.addresses[0].building": "",
      "company.addresses[0].apartment": "",

      "company.addresses[1].index": "",
      "company.addresses[1].country": "",
      "company.addresses[1].city": "",
      "company.addresses[1].street": "",
      "company.addresses[1].house": "",
      "company.addresses[1].building": "",
      "company.addresses[1].apartment": "",

      "company.bank_accounts[0].bank_name": "",
      "company.bank_accounts[0].bik": "",
      "company.bank_accounts[0].correspondent_account": "",
      "company.bank_accounts[0].current_account": "",
      "company.contracts[0].number": "",
      "company.contracts[0].start_date": null,
      unmaskedStartDate: "",
      "company.contracts[0].end_date": null,
      unmaskedEndDate: "",
    };
  },
});
