<template>
  <!-- Add Employee Modal -->
  <div id="resource_release_fixed_card" class="modal custom-modal show d-flex" role="dialog" ref='modalWindowRef'
    @mousedown='localOnClose'>>
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <h5 class="modal-title text-bold">Оставить отклик </h5>
            <p class='gray-text text-center'>№ Запроса {{ name }}</p>
          </div>
          <button type="button" class="btn-close" @click='onClose(false)'>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 class='text-bold'>Прикрепите коммерческое предложение </h3>
          <p class='gray-text text-sm'>Поддерживаемый формат: {{ allowedExts.join(', ') }}</p>
          <core-form url="/fp_resources" :auth='true' :initialValidation='resourceSchema'
            v-slot='{ errors, isLoading, setErrors }' :additionalData='additionalData' :onSuccess='onSuccess'
            :onError='onError'>
            <div class='fix-price-card__form-container'>
              <div>
                <div class='mb-3'>
                  <core-numeric-input :required='true' label='Стоимость' :showLabel='true' :name='`responses[0].cost`'
                    :data='data' :errors='errors' :showError='true' mask="A" :maskOptions='numberStringOptions' />
                </div>
                <div class='mb-4 resource-container__btn-close position-relative'>
                  <core-single-file-input :allowedExts='allowedExts' :data='data' :name='`responses[0].file`'
                    :showError='true' :errors='errors' :setErrors='setErrors' />
                </div>
              </div>
              <div class="buttons_container">
                <core-submit-button :changeBeforeSubmit='true' type='button'
                  :onClick='(e, submit) => validateBeforeSubmit(submit)' className="btn btn-primary submit-btn"
                  text='Отправить отклик' :isLoading='isLoading' />
                <button class="btn btn-outline-primary submit-btn" type='button' @click='onClose(false)'>Отмена</button>
              </div>
            </div>
          </core-form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</template>
<script setup>
import { ref, reactive, onUnmounted } from 'vue';
import { numberStringOptions } from "@/shared/validation/index.ts";
import { useToast } from 'vue-toast-notification';
import { resourceSchema } from '@/shared/validation/createFixPriceResource.ts';
const props = defineProps({
  id: Number | String,
  name: { type: String },
  onClose: Function,
});

const $toast = useToast();
const modalWindowRef = ref();
const additionalData = {
  fp_request_id: props.id
}

const data = reactive({
  'responses[0].cost': '',
  'responses[0].file': '',
});

const noFieldDetectedError = `
  <div class='error-core-container'>
    <div class='error-core-container__cross'>
      <b-button type="button" class="btn-close text-reset" aria-label="Close"></b-button>
    </div>
    <p class="py-0 my-0 w-100">Ошибка!</p>
    <p class="py-0 my-0 w-100">Вы не можете отправить пустой отклик</p>
  </div>
`

const createErrorToast = (template) => {
  $toast.open({
    type: 'error',
    message: template,
    duration: 5000,
    position: 'bottom-left'
  });
}

const validateBeforeSubmit = (submit) => {
  $toast.clear();
  let isFailed = true;
  for (const [key, value] of Object.entries(data)) {
    if (value) {
      isFailed = false;
    }
  }

  if (isFailed) {
    createErrorToast(noFieldDetectedError);
  } else {
    submit();
  }

}

const allowedExts = ['DOC', 'DOCX', 'JPEG', 'PNG', 'XLS', 'CSV', 'PPT', 'TXT', 'RTF', 'PDF', 'TIFF'];

const localOnClose = (event) => {
  if (event.target === modalWindowRef.value) {
    props.onClose(false)
  }
}

const onSuccess = () => {
  props.onClose(false);
}

const onError = (errors) => {
  if (errors.data && errors.data.errors && errors.data.errors.responses) {
    const errorString = errors.data.errors.responses[0].split('.')
    const limitError = `
      <div class='error-core-container'>
        <div class='error-core-container__cross'>
          <b-button type="button" class="btn-close text-reset" aria-label="Close"></b-button>
        </div>
        <p class="py-0 my-0 w-100">Ошибка!</p>
        <p class="py-0 my-0 w-100">${errorString[0]}.</p>
        <p class="py-0 my-0 w-100">${errorString[1]}</p>
      </div>
    `

    createErrorToast(limitError);
  }
}

onUnmounted(() => {
  $toast.clear();
});

</script>