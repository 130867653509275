import { getTechnicalFieldsFromServer } from "@/features/select-competencies/index.ts";

export const getTechnicalFields = async ({ text, url }) => {
  try {
    let currentUrl =
      text && text.search_query
        ? `${url}?search_query=${text.search_query}`
        : url;

    let { data, meta } = await getTechnicalFieldsFromServer({
      url: currentUrl,
    });

    if (meta.deleted_entities && meta.deleted_entities.technical_fields) {
      data = data.concat(meta.deleted_entities.technical_fields);
    }

    return data;
  } catch (e) {}
};
