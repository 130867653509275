import request from "@/shared/core-api/index.ts";

export const getUserDataFromServer = async () => {
  try {
    const { data } = await request("/me", {
      auth: true,
    });

    return data;
  } catch (e) {
    throw e;
  }
};
