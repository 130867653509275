import * as Yup from "yup";
import { FieldsValidation } from "@/shared/validation/index.ts";

export const getEditResourceCompanySchema = (isIPRole) => {
  return Yup.object().shape({
    company: Yup.object().shape({
      type: FieldsValidation.notEmptyString,
      short_name: FieldsValidation.notEmptyString,
      full_name: FieldsValidation.notEmptyString,
      tin: FieldsValidation.tin.required("Поле не может быть пустым"),
      okpo: FieldsValidation.okpo.required("Поле не может быть пустым"),
      ogrn: FieldsValidation.ogrn.required("Поле не может быть пустым"),
      kpp: isIPRole
        ? null
        : FieldsValidation.kpp.required("Поле не может быть пустым"),
      okved: FieldsValidation.okved.required("Поле не может быть пустым"),
      phone: FieldsValidation.phoneNumber,
      addresses: Yup.array().of(
        Yup.object().shape({
          index: FieldsValidation.index.required("Поле не может быть пустым"),
          country: FieldsValidation.notEmptyString,
          city: FieldsValidation.notEmptyString,
          street: FieldsValidation.notEmptyString,
          house: FieldsValidation.notEmptyString,
          building: FieldsValidation.notEmptyString,
          apartment: FieldsValidation.notEmptyString,
        })
      ),
      bank_accounts: Yup.array().of(
        Yup.object().shape({
          bank_name: FieldsValidation.notEmptyString,
          bik: FieldsValidation.bik.required("Поле не может быть пустым"),
          correspondent_account:
            FieldsValidation.correspondent_account.required(
              "Поле не может быть пустым"
            ),
          current_account: FieldsValidation.correspondent_account.required(
            "Поле не может быть пустым"
          ),
        })
      ),
      contracts: Yup.array().of(
        Yup.object().shape({
          number: FieldsValidation.agreementNumber,
          start_date: FieldsValidation.notEmptyString,
          end_date: FieldsValidation.notEmptyString,
        })
      ),
    }),
  });
};
