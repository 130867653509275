<template>
  <core-form url='/fp_applications' :initialValidation='schema' :additionalData='additionalData'
    v-slot='{ errors, isLoading, setErrors }' :onSuccess="onSuccess" :auth='true'>
    <div class='approve-tam-request__table'>
      <div class='approve-fp-request__cell'>
        <p>Исполнитель</p>
        <p v-for="(item) in approvedWorkers" :key="item.id" class='mb-3'>{{ item.company_name }}</p>
      </div>
      <div class='approve-fp-request__cell'>
        <p>Ставка</p>
        <p v-for="(item) in approvedWorkers" :key="item.id" class='mb-3'>{{ item.cost &&
          Number(item.cost).toLocaleString() }} руб</p>
      </div>
      <div class='approve-fp-request__cell'>
        <p>Дата создания</p>
        <p v-for="(item) in approvedWorkers" :key="item.id" class='mb-3'>{{
          fromTimestampToISOString(item.created_at * 1000) }}</p>
      </div>
      <div class='approve-fp-request__checkbox'>
        <div v-for="(item) in approvedWorkers" :key="item.id" class='mb-3'><core-checkbox-input :data='item'
            :isIgnore="true" name='checked' disabled /></div>
      </div>
    </div>
    <div class='mb-4 resource-container__btn-close position-relative'>
      <div class='p-2'>
        <h4 class='text-bold gray-text mb-2 text-md' style='width: 100%; font-size: 16px;'>Прикрепите документы для
          согласования
          с исполнителем</h4>
        <p class='gray-text' style='width: 100%; font-size: 11px;'>Поддерживаемый формат: DOC, DOCX, JPEG, PNG,
          XLS, SCV, PPT, TXT, RTF, PDF, TIFF
        </p>
      </div>
      <core-multiple-files-input :allowedExts='allowedExts' :data='data' name='files' :errors='errors' :showError='true'
        :setErrors='setErrors' />
    </div>
    <div class="buttons_container">
      <core-submit-button className="btn btn-primary submit-btn" :disabled='isDisabled' text='Далее'
        :isLoading='isLoading' />
      <button class="btn btn-outline-primary submit-btn" type='button' @click='onClose(false)'>Отмена</button>
    </div>
  </core-form>
</template>
<script setup>
import { reactive, computed } from 'vue';
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
import { schema } from "@/shared/validation/approveFixPriceRequest.ts";
import { useCreateToast } from '../../utils/useCreateToast.ts';
import { primaryAllowedExts as allowedExts } from '@/shared/core-form/index.ts';
const props = defineProps({
  approvedWorkers: Object,
  id: String,
  onClose: Function,
  requestId: String,
});

const data = reactive({
  files: []
});

const additionalData = {
  user_id: props.id,
  request_id: props.requestId,
  'responses[0][id]': props.approvedWorkers[0].id,
}

const onSuccess = (values) => {
  useCreateToast(values.number);
  props.onClose(false)
}

const isDisabled = computed(() => {
  let disabled = false;
  Object.entries(data).forEach(([key, value]) => {
    if (!value && value !== 0 && value !== '0') {
      disabled = true;
    }
  });
  return disabled
});

</script>