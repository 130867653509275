import axios from "axios";
import { getCookie, deleteCookie } from "@/shared/utils/cookie.ts";
import config from "@/config.ts";
import { useToast } from "vue-toast-notification";
import { useUserStore } from "@/stores/user.ts";
import { redirectUnauthorizedUsers } from './redirectUnauthorizedUsers.ts';

export default async function request(input, init) {
  const { BASE_URL } = config;
  return new Promise(async (resolve, reject) => {
    try {
      // Инициализируем данные по умолчанию
      let initLocal = {
        headers: {},
      };
      // Если есть инициализирующие настройки, добавляем хедеры
      if (init) {
        const { auth, queryParamsBody, ...externalInit } = init;
        initLocal = {
          ...initLocal,
          ...externalInit,
        };
        if (auth) {
          const token = getCookie("token");
          initLocal.headers.Authorization = `Bearer ${token}`;
        }
        if (queryParamsBody) {
          initLocal.params = queryParamsBody;
        }
      }
      const res = await axios({
        baseURL: BASE_URL,
        url: input,
        ...initLocal,
      });
      resolve(res.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        deleteCookie('token');
        const user = useUserStore();
        user.$reset();
        redirectUnauthorizedUsers();
        return;
      }
      if (error.response && error.response.status === 429) {
        const $toast = useToast();
        $toast.open({
          type: "error",
          duration: 5000,
          message: `
          <div class='error-core-container'>
            <p class="py-0 my-0 w-100">Ошибка!</p>
            <p class="py-0 my-0 w-100">Превышено максимальное число запросов</p>
            <p class="py-0 my-0 w-100">Пожалуйста, попробуйте повторить запрос немного позже</p>
          </div>
        `,
          position: "bottom-left",
        });
      }
      reject(error.response);
    }
  });
}
