import request from "@/shared/core-api/index.ts";
export const getCompetenciesData = async () => {
  try {
    const { data } = await request("/me/technical_fields", {
      auth: true,
    });

    return data;
  } catch (e) {}
};
