<template>
  <div class="row filter-row table-filter-row">
    <div class="col flex-grow-1">
      <div class="input-block mb-3 form-focus">
        <div class="input-group">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control floating py-0" v-model="filters.search_query" placeholder='Поиск' />
        </div>
      </div>
    </div>

    <div class="col-sm table-filter-row_resource-tam-row">
      <div class="input-block mb-2 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='status'
          :options="mode === ShowTableModeEnum.Requests ? requestStatusOptions : resourceStatusOptions"
          placeholder="По статусу" />
      </div>
    </div>

    <div v-if='mode === ShowTableModeEnum.Requests' class="col-sm table-filter-row_resource-tam-row">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='project_id' :options="projectOptions"
          placeholder="По проекту" valueProp='id' displayName='title' />
      </div>
    </div>

    <div v-if='mode === ShowTableModeEnum.Requests' class="col-sm table-filter-row_resource-tam-row">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='work_type' :options="workTypeOptions"
          placeholder="По Типу" />
      </div>
    </div>

    <div class="col-sm table-filter-row_resource-tam-row">
      <button class="btn btn-success w-100 text-capitalize" @click="resetFilters">
        Сбросить фильтры
      </button>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import request from '@/shared/core-api/index.ts';
import { getEnums } from '@/shared/core-api/getEnums.ts';
import { formatStringArrForCoreSelect } from '@/shared/core-form/index.ts';
import { ShowTableModeEnum } from "@/pages/requests/index.ts"
import { getNotArchivedProjects } from "@/shared/projects/index.ts";

const props = defineProps({
  filters: Object,
  mode: String,
  resetFilters: Function,
});

const requestStatusOptions = ref([]);
const resourceStatusOptions = ref([]);
const projectOptions = ref([]);
const workTypeOptions = ref([]);

onMounted(async () => {
  try {
    const { tam_request_work_types } = await getEnums(['tam_request_work_types'], {
      auth: true
    })
    requestStatusOptions.value = formatStringArrForCoreSelect(['Новый', 'На рассмотрении']);
    resourceStatusOptions.value = formatStringArrForCoreSelect(['Новый', 'На рассмотрении', 'Согласовано', 'Отказано']);
    workTypeOptions.value = formatStringArrForCoreSelect(tam_request_work_types);
    const responseData = await getNotArchivedProjects();

    projectOptions.value = responseData;
  } catch (e) {

  }
})

</script>
