import request from "@/shared/core-api/index.ts";
import { MAX_LIMIT_PROJECTS_COUNT } from "@/shared/projects/index.ts";

export const getNotArchivedProjects = async () => {
  try {
    const { data } = await request(
      `/projects?archived=0&per_page=${MAX_LIMIT_PROJECTS_COUNT}`,
      {
        auth: true,
      }
    );

    return data;
  } catch (e) {
    throw e;
  }
};
