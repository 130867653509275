<template>
  <div class="row filter-row table-filter-row">
    <div class="col flex-grow-1">
      <div class="input-block mb-3 form-focus">
        <div class="input-group">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control floating py-0" v-model="filters.search_query" placeholder='Поиск' />
        </div>
      </div>
    </div>

    <div class="col-sm table-filter-row_resource-tam-row">
      <div class="input-block mb-2 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='status'
          :options="mode === ShowTableModeEnum.Requests ? requestStatusOptions : resourceStatusOptions"
          placeholder="По статусу" />
      </div>
    </div>

    <div v-if='mode === ShowTableModeEnum.Requests' class="col-sm table-filter-row_resource-tam-row">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='project_id' :options="projectOptions"
          placeholder="По проекту" valueProp='id' displayName='title' />
      </div>
    </div>

    <div class="col-sm table-filter-row_resource-tam-row">
      <button class="btn btn-success w-100 text-capitalize" @click="resetFilters">
        Сбросить фильтры
      </button>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { getNotArchivedProjects } from "@/shared/projects/index.ts";
import { formatStringArrForCoreSelect } from '@/shared/core-form/index.ts';
import { ShowTableModeEnum } from "@/pages/requests/index.ts"

const props = defineProps({
  resetFilters: Function,
  mode: String,
  filters: Object,
});

const requestStatusOptions = ref([]);
const resourceStatusOptions = ref([]);
const projectOptions = ref([]);

onMounted(async () => {
  try {
    requestStatusOptions.value = formatStringArrForCoreSelect(['Новый', 'На рассмотрении']);
    resourceStatusOptions.value = formatStringArrForCoreSelect(['Новый', 'На рассмотрении', 'Согласовано', 'Отказано']);
    const responseData = await getNotArchivedProjects();

    projectOptions.value = responseData;
  } catch (e) {

  }
});

</script>
