<template>
  <div class="row filter-row table-filter-row">
    <div class="col flex-grow-1">
      <div class="input-block mb-3 form-focus">
        <div class="input-group">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control floating py-0" v-model="filters.search_query" placeholder='Поиск' />
        </div>
      </div>
    </div>

    <div class="col-sm table-filter-row_bid-row">
      <div class="input-block mb-2 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='status' :options="statusOptions"
          placeholder="По статусу" />
      </div>
    </div>

    <div class="col-sm table-filter-row_bid-row">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='project_id' :options="projectOptions"
          placeholder="По проекту" valueProp='id' displayName='title' />
      </div>
    </div>

    <div v-if='!user.isResourseManagerRole' class="col-sm table-filter-row_bid-row">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='user_id' :options="executorOptions"
          placeholder="По исполнителю" valueProp='user_id' displayName='short_name' />
      </div>
    </div>

    <div class="col-sm table-filter-row_bid-row">
      <button class="btn btn-success w-100 text-capitalize" @click="resetFilters">
        Сбросить фильтры
      </button>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import request from '@/shared/core-api/index.ts';
import { useUserStore } from '@/stores/user.ts';
import { formatStringArrForCoreSelect } from '@/shared/core-form/index.ts';
import { getEnums } from '@/shared/core-api/getEnums.ts';
import { getNotArchivedProjects } from "@/shared/projects/index.ts";
const props = defineProps({
  filters: Object,
  resetFilters: Function,
});

const user = useUserStore();
const statusOptions = ref([]);
const projectOptions = ref([]);
const executorOptions = ref([]);

onMounted(async () => {
  try {
    if (user.isResourseManagerRole) {
      const { resource_application_statuses } = await getEnums(['resource_application_statuses'], {
        auth: true
      })

      statusOptions.value = formatStringArrForCoreSelect(resource_application_statuses);
    } else {
      const { application_statuses } = await getEnums(['application_statuses'], {
        auth: true
      })

      statusOptions.value = formatStringArrForCoreSelect(application_statuses);
    }

    const projectOptionsData = await getNotArchivedProjects();
    projectOptions.value = projectOptionsData;


    const { data: executerOptionsData } = await request('/tam_applications/executors', {
      method: 'GET',
      auth: true,
    });

    executorOptions.value = executerOptionsData;
  } catch (e) {

  }
})

</script>
