<template>
  <div class="offcanvas offcanvas-end show core-canvas__container " tabindex="-1" id="add_employee"
    aria-labelledby="add_employeeLabel">
    <modal-header title='Карточка проекта' :isShowSeparator='false' :onClose='() => onClose()' />

    <div class="offcanvas-body">
      <div class="card-body">
        <ul class="nav nav-tabs nav-tabs-bottom nav-justified core-canvas__tabs">
          <li class="nav-item core-canvas__tab" @click='setMode(ContractModelEnum.TAM)'>
            <a class="nav-link active" href="#bottom-justified-tab1" data-bs-toggle="tab">T&M</a>
          </li>
          <li class="nav-item core-canvas__tab" @click='setMode(ContractModelEnum.FIX_PRICE)'>
            <a class="nav-link" href="#bottom-justified-tab2" data-bs-toggle="tab">Fix Priced</a>
          </li>
        </ul>
        <div class="row mt-4">
          <div class="col-md-12">
            <core-table v-if='isShowTable' :url='url' ref='tableRef' :headers='headers.slice(1, -1)' :minHeight='300'
              :initialData='initialFilters'>
              <template #item-status="{ status }">
                <div :class='getStatusClass(status)' class='p-2 text-center'>
                  {{ status }}
                </div>
              </template>
              <template #item-project_name="{ project }">
                <div>
                  {{ project && project.name }}
                </div>
              </template>
              <template #item-created_at='{ created_at }'>
                <div>
                  {{ fromTimestampToISOString(created_at && created_at * 1000) }}
                </div>
              </template>
              <template #item-pay_rate="{ pay_rate }">
                {{ pay_rate && Number(pay_rate).toLocaleString() }} руб
              </template>
              <template #item-cost="{ cost }">
                {{ cost && Number(cost).toLocaleString() }} руб
              </template>
              <template #item-kp_date='{ kp_date }'>
                <div>
                  {{ fromTimestampToISOString(kp_date && kp_date * 1000) }}
                </div>
              </template>
            </core-table>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="offcanvas-backdrop fade show" @click='() => onClose(false)'></div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { getStatusClass, tmHeaders, fpHeaders, resourceTMHeaders, resourcesFPHeaders } from '@/pages/requests/index.ts';
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
import { ContractModelEnum, EntitiesEnum } from '@/shared/enums/index.ts';
import { applicationResourceManagerHeaders } from '@/widgets/bid/index.ts';
const props = defineProps({
  onClose: Function,
  initialFilters: Object,
  type: String,
});

const tableRef = ref();
const mode = ref(ContractModelEnum.TAM);
const isShowTable = ref(true);
const setMode = (value) => {
  isShowTable.value = false;
  setTimeout(() => {
    mode.value = value;
    isShowTable.value = true;
  });
}

const url = computed(() => {

  if (mode.value === ContractModelEnum.FIX_PRICE && props.type === EntitiesEnum.RESOURCES) {
    return '/fp_resources'
  }

  if (props.type === EntitiesEnum.RESOURCES) {
    return '/tam_resources'
  }

  if (mode.value === ContractModelEnum.FIX_PRICE && props.type === EntitiesEnum.BID) {
    return '/fp_applications'
  }

  if (props.type === EntitiesEnum.BID) {
    return '/tam_applications'
  }

  if (mode.value === ContractModelEnum.FIX_PRICE) {
    return '/fp_requests'
  }

  return '/tam_requests'
});

const headers = computed(() => {
  if (mode.value === ContractModelEnum.FIX_PRICE && props.type === EntitiesEnum.RESOURCES) {
    return resourcesFPHeaders
  }

  if (props.type === EntitiesEnum.RESOURCES) {
    return resourceTMHeaders
  }

  if (props.type === EntitiesEnum.BID) {
    return applicationResourceManagerHeaders;
  }

  if (mode.value === ContractModelEnum.FIX_PRICE) {
    return fpHeaders
  }

  return tmHeaders;
});

</script>
