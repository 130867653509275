import { getEnums } from "@/shared/core-api/getEnums.ts";
import { computed, reactive, ref, watch } from "vue";
import request from "@/shared/core-api/index.ts";
import { ModeEnums } from "@/pages/requests/index.ts";
import { RequestTypeEnum } from "./requestEnums.ts";
import { getNotArchivedProjects } from "@/shared/projects/index.ts";

export function useRequest({
  setAdditionalData,
  props,
  initialData = {},
  url,
}) {
  const data = reactive({
    name: "",
    start_date: null,
    unmaskedStartDate: "",
    end_date: null,
    unmaskedEndDate: "",
    project_id: "",
    funding_type: "",
    description: "",
    competence_project_role_id: "",
    competence_tf_id: "",
    competence_grade_id: "",
    competence_tf_requirement_id: "",
    competence_tf_duty_id: "",
    title: "",
    ...initialData,
  });
  const dataFromServer = reactive({});
  const cityOptions = ref([]);
  const employmentOptions = ref([]);
  const workTypeOptions = ref([]);
  const fundingOptions = ref([]);
  const projectOptions = ref([]);
  const projectRolesOptions = ref([]);
  const projectTfOptions = ref([]);
  const projectGradesOptions = ref([]);
  const dutiesOptions = ref([]);
  const requirementsOptions = ref([]);
  const metaData = ref({});
  const isDataLoading = ref(false);
  const statusRef = ref("");
  const isSkipDescriptionUpdate = ref(false);

  const getProjectData = async () => {
    try {
      const responseData = await getNotArchivedProjects();
      projectOptions.value = responseData;
    } catch (e) {}
  };

  watch(
    () => data.project_id,
    (id) => {
      if (isSkipDescriptionUpdate.value) {
        isSkipDescriptionUpdate.value = false;
        return;
      }
      const project = projectOptions.value.find((project) => project.id === id);
      if (!project) {
        data.description = "";
        return;
      }
      data.description = project.description;
    },
    { deep: true }
  );

  const getProjectRoles = async () => {
    try {
      const {
        competencies_project_roles,
        competencies_technical_fields,
        competencies_grades,
        tam_request_cities,
        tam_request_employment_forms,
        tam_request_work_types,
        tam_request_funding_types,
      } = await getEnums(
        [
          "competencies_project_roles",
          "competencies_technical_fields",
          "competencies_grades",
          "tam_request_cities",
          "tam_request_employment_forms",
          "tam_request_work_types",
          "tam_request_funding_types",
        ],
        true
      );

      projectRolesOptions.value = competencies_project_roles;
      projectTfOptions.value = competencies_technical_fields;
      projectGradesOptions.value = competencies_grades;
      cityOptions.value = tam_request_cities;
      employmentOptions.value = tam_request_employment_forms;

      workTypeOptions.value = tam_request_work_types;
      fundingOptions.value = tam_request_funding_types;
    } catch (e) {}
  };

  const getRequirementAndDutiesOptions = async (id) => {
    try {
      const currentOption = projectTfOptions.value.find((tf) => tf.id === id);
      if (
        currentOption &&
        currentOption.deleted_at &&
        metaData.value.deleted_entities
      ) {
        const duty = metaData.value.deleted_entities.tf_duty;
        dutiesOptions.value.push(duty);
        const requirement = metaData.value.deleted_entities.tf_requirement;
        requirementsOptions.value.push(requirement);
      } else {
        const { data: duties } = await request(
          `enums/get_tf_duties?competence_tf_id=${id}`,
          {
            auth: true,
          }
        );
        const { data: requirements } = await request(
          `enums/get_tf_requirements?competence_tf_id=${id}`,
          {
            auth: true,
          }
        );
        dutiesOptions.value = duties;
        requirementsOptions.value = requirements;
      }
    } catch (e) {}
  };

  const getRequestData = async (id) => {
    try {
      isDataLoading.value = true;
      const { data: responseData, meta } = await request(`${url}/${id}`, {
        auth: true,
      });

      metaData.value = meta;
      data.name = responseData.name;
      data.start_date = new Date(responseData.start_date * 1000);
      data.end_date = new Date(responseData.end_date * 1000);

      const project = responseData.project;
      if (project.id) {
        data.project_id = project.id;
        if (project.archived) {
          projectOptions.value.push(meta.archived_entities.project);
        }
      }

      data.funding_type = responseData.funding_type;

      data.description = responseData.description;
      if (data.description) {
        isSkipDescriptionUpdate.value = true;
      }

      const projectRole = responseData.project_role;
      if (projectRole) {
        data.competence_project_role_id = projectRole.id;
        if (projectRole.deleted_at) {
          projectRolesOptions.value.push(meta.deleted_entities.project_role);
        }
      }

      const technicalField = responseData.technical_field;
      if (technicalField) {
        data.competence_tf_id = technicalField.id;
        if (technicalField.deleted_at) {
          projectTfOptions.value.push(meta.deleted_entities.technical_field);
          dutiesOptions.value.push(meta.deleted_entities.tf_duty);
          requirementsOptions.value.push(meta.deleted_entities.tf_requirement);
        }
      }

      const grade = responseData.grade;
      if (grade) {
        data.competence_grade_id = grade.id;
        if (grade.deleted_at) {
          projectGradesOptions.value.push(meta.deleted_entities.grade);
        }
      }

      data.competence_tf_requirement_id = responseData.tf_requirement
        ? responseData.tf_requirement.id
        : "";
      data.competence_tf_duty_id = responseData.tf_duty
        ? responseData.tf_duty.id
        : "";

      data.title = responseData.title;
      setAdditionalData && setAdditionalData(responseData, data);
      Object.assign(dataFromServer, data);
      statusRef.value = responseData.status;
      isDataLoading.value = false;
    } catch (e) {
      isDataLoading.value = false;
    }
  };

  const title = computed(() => {
    if (props.mode === ModeEnums.EDIT) {
      return `Редактировать ${data.title}`;
    } else {
      return "Новый запрос";
    }
  });

  const dialogModalRef = ref();
  const dialogOptions = {
    dialogTitle: "Сбросить данные",
    dialogBody:
      "Вы уверены, что хотите закрыть окно создания нового запроса? При закрытии введенные данные не сохранятся.",
    dialogOnSuccessButton: "Продолжить",
    dialogOnCancelButton: "Отмена",
    dialogOnSuccess: () => {
      props.onClose(false, null);
    },
  };

  const compareValues = (keysArr) => {
    let isChanged = false;
    keysArr.forEach((key) => {
      if (data[key] !== dataFromServer[key]) {
        if (key.includes("unmasked")) {
          return;
        }
        isChanged = true;
      }
    });

    return isChanged;
  };

  const isChanged = computed(() => {
    if (!props.id) {
      return true;
    }

    return compareValues(Object.keys(data));
  });

  const onSuccess = () => {
    props.onClose(false, null);
  };

  const onCloseHandler = () => {
    dialogModalRef.value.click();
  };

  const requestMode = ref(RequestTypeEnum.REQUEST);

  return {
    getProjectData,
    getProjectRoles,
    getRequestData,
    getRequirementAndDutiesOptions,
    cityOptions,
    employmentOptions,
    workTypeOptions,
    fundingOptions,
    projectOptions,
    projectRolesOptions,
    projectTfOptions,
    projectGradesOptions,
    dutiesOptions,
    requirementsOptions,
    data,
    title,
    dialogModalRef,
    dialogOptions,
    isChanged,
    onSuccess,
    onCloseHandler,
    requestMode,
    isDataLoading,
    statusRef,
  };
}
