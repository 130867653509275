<template>
  <dialog-modal :title='dialogTitle' :body='dialogBody' :onSuccessButton='dialogOnSuccessButton'
    :onCancelButton='dialogOnCancelButton' :onSuccess='dialogOnSuccess' />
  <div class="offcanvas offcanvas-end show core-canvas__container " tabindex="-1" id="add_employee"
    aria-labelledby="add_employeeLabel">
    <modal-header title='Карточка пользователя' :isShowSeparator='true' :onClose='() => onClose(false)' />

    <div class="offcanvas-body">
      <div class="card-body">
        <core-form url="/admin/users" :onSuccess="onSuccess"
          :initialValidation="isResourseRole ? resourseManagerSchema : managerSchema" v-slot="{ errors, isLoading }"
          :auth='true'>
          <div class="input-block mb-3">
            <label>Выберите роль пользователя <span class="text-danger">*</span></label>
            <core-select-input name='user.role' placeholder='Выберите роль' :options="roleOptions" :data='data'
              :showError='true' :errors='errors' :settings="{ width: '100%', dropdownParent: '#add_employee' }" />
          </div>
          <h3>Личные данные</h3>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <label class="col-form-label">Фамилия Имя Отчество <span class="text-danger">*</span></label>
                <core-text-input name='user.name' :data='data' :showError='true' :errors='errors'
                  placeholder='Введите ваше ФИО' :stringOptions="stringOptions" mask="A A A"
                  :tooltip='TooltipEnum.NAME' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <label class="col-form-label">Электронная почта <span class="text-danger">*</span></label>
                <core-email-input name='user.email' :tooltip='TooltipEnum.EMAIL' :data='data' :showError='true'
                  :errors='errors' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <label class="col-form-label">Мобильный номер телефона </label>
                <core-phone-input placeholder="+7 (912) 555 22 22" name='user.phone' :data='data' :showError='true'
                  :errors='errors' maskedValueFieldName='maskedUserPhone' :tooltip='TooltipEnum.PHONE' />
              </div>
            </div>
          </div>

          <div v-if='isResourseRole'>
            <h3>Реквизиты компании</h3>
            <div class="row">
              <div class="input-block mb-3">
                <label class="col-form-label">Организационно-правовая форма <span class="text-danger">*</span></label>
                <core-select-input name='company.type' :options="companyOptions" :data='data' :showError='true'
                  :errors='errors' placeholder="Введите организационно-правовую форму" />
              </div>
            </div>
            <div v-if='data["company.type"]'>
              <div class="row">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Полное наименование компании</label>
                    <core-text-input name="company.full_name" placeholder="Введите полное наименование компании"
                      :data='data' :showError='true' :errors='errors' :tooltip='TooltipEnum.COMPANY_FULL_NAME' />
                  </div>
                </div>
              </div>
              <div class='row'>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Сокращенное наименование компании <span
                        class="text-danger">*</span></label>
                    <core-text-input name="company.short_name" placeholder="Введите сокращенное наименование компании"
                      :data='data' :showError='true' :errors='errors' :tooltip='TooltipEnum.COMPANY_SHORT_NAME' />
                  </div>
                </div>
              </div>
              <div class="row input-container__column_adaptive-md">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">ИНН</label>
                    <core-numeric-input :data='data' name="company.tin"
                      :placeholder='isIPRole ? TooltipEnum.TIN_IP : TooltipEnum.TIN'
                      :mask="isIPRole ? '############' : '##########'" :showError='true' :errors='errors'
                      :tooltip='isIPRole ? TooltipEnum.TIN_IP : TooltipEnum.TIN' />
                  </div>
                </div>

                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">{{ isIPRole ? 'ОГРН ИП' : 'ОГРН' }}</label>
                    <core-numeric-input :data='data' name="company.ogrn"
                      :placeholder='isIPRole ? 123456789012345 : 123456789012'
                      :tooltip='isIPRole ? TooltipEnum.OGRN_IP : TooltipEnum.OGRN'
                      :mask="isIPRole ? '###############' : '#############'" :showError='true' :errors='errors' />
                  </div>
                </div>

                <div v-if="!isIPRole" class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">КПП</label>
                    <core-numeric-input :data='data' name="company.kpp" placeholder='123456789' mask="#########"
                      :showError='true' :errors='errors' :tooltip='TooltipEnum.KPP' />
                  </div>
                </div>

                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">ОКПО</label>
                    <core-numeric-input :data='data' name="company.okpo"
                      :placeholder='isIPRole ? TooltipEnum.OKPO_IP : TooltipEnum.OKPO'
                      :tooltip='isIPRole ? TooltipEnum.OKPO_IP : TooltipEnum.OKPO'
                      :mask="isIPRole ? '##########' : '########'" :showError='true' :errors='errors' />
                  </div>
                </div>
              </div>
              <div class='row input-container__column_adaptive-md'>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">ОКВЭД</label>
                    <core-text-input name="company.okved" placeholder='12.34.56' mask='AAAAAAAA'
                      :tooltip='TooltipEnum.OKVED' :stringOptions='okvedOptions' :data='data' :showError='true'
                      :errors='errors' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Номер телефона компании</label>
                    <core-phone-input placeholder="+7 (912) 555 22 22" name='company.phone' :data='data'
                      :showError='true' :errors='errors' :tooltip='TooltipEnum.PHONE'
                      maskedValueFieldName='maskedCompanyPhone' />
                  </div>
                </div>
                <div class="col d-flex" v-if="!isIPRole">
                  <div class="input-block mb-3">
                    <label class='invisible-label'></label>
                    <core-radio-input :data='data' name='company.with_vat' :options='radioOptions'
                      :initialValue='data["company.with_vat"]' className='d-flex' />
                  </div>
                </div>
              </div>
              <h3>Юридический адрес компании</h3>
              <div class="row input-container__column_adaptive-md">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Индекс</label>
                    <core-numeric-input :data='data' name="company.addresses[0].index" placeholder='123456'
                      mask="######" :showError='true' :errors='errors' :tooltip='TooltipEnum.INDEX' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Страна</label>
                    <core-text-input name="company.addresses[0].country" placeholder="Введите название страны"
                      :tooltip='TooltipEnum.COUNTRY' :data='data' :showError='true' :errors='errors'
                      :stringOptions='stringOptions' mask='A' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Город, населенный пункт</label>
                    <core-text-input name="company.addresses[0].city" placeholder="г. Москва" :data='data'
                      :tooltip='TooltipEnum.CITY' :showError='true' :errors='errors' />
                  </div>
                </div>
              </div>
              <div class="row input-container__column_adaptive-lg">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Улица</label>
                    <core-text-input name="company.addresses[0].street" :tooltip='TooltipEnum.STREET'
                      placeholder="Ильфата Закирова" :data='data' :showError='true' :errors='errors' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Дом</label>
                    <core-text-input name="company.addresses[0].house" placeholder="25д" :data='data' :showError='true'
                      :errors='errors' :tooltip='TooltipEnum.HOUSE' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Корпус</label>
                    <core-text-input name="company.addresses[0].building" placeholder="6" :data='data' :showError='true'
                      :errors='errors' :tooltip='TooltipEnum.BUILDING' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Квартира, офис</label>
                    <core-text-input name="company.addresses[0].apartment" placeholder="оф. 50" :data='data'
                      :showError='true' :errors='errors' :tooltip='TooltipEnum.APARTMENT' />
                  </div>
                </div>
              </div>
              <div class='mb-3'>
                <core-checkbox-input name='company.is_actual_address_equal_legal' :data='data' :isNumberRequired='true'
                  label='Юридический адрес совпадает с фактическим' />
              </div>
              <div v-if="!data['company.is_actual_address_equal_legal']" class='row'>
                <h3>Фактический адрес компании</h3>
                <div class="row input-container__column_adaptive-md">
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Индекс</label>
                      <core-numeric-input :data='data' name="company.addresses[1].index" placeholder='123456'
                        mask="######" :showError='true' :errors='errors' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Страна</label>
                      <core-text-input name="company.addresses[1].country" placeholder="Введите название страны"
                        :data='data' :showError='true' :errors='errors' :tooltip='TooltipEnum.COUNTRY'
                        :stringOptions='stringOptions' mask='A' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Город, населенный пункт</label>
                      <core-text-input name="company.addresses[1].city" :tooltip='TooltipEnum.CITY'
                        placeholder="г. Москва" :data='data' :showError='true' :errors='errors' />
                    </div>
                  </div>
                </div>
                <div class="row input-container__column_adaptive-lg">
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Улица</label>
                      <core-text-input name="company.addresses[1].street" placeholder="Ильфата Закирова" :data='data'
                        :showError='true' :errors='errors' :tooltip='TooltipEnum.STREET' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Дом</label>
                      <core-text-input name="company.addresses[1].house" placeholder="25д" :data='data'
                        :showError='true' :errors='errors' :tooltip='TooltipEnum.HOUSE' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Корпус</label>
                      <core-text-input name="company.addresses[1].building" placeholder="6" :data='data'
                        :showError='true' :errors='errors' :tooltip='TooltipEnum.BUILDING' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Квартира, офис</label>
                      <core-text-input name="company.addresses[1].apartment" placeholder="оф. 50" :data='data'
                        :showError='true' :errors='errors' :tooltip='TooltipEnum.APARTMENT' />
                    </div>
                  </div>
                </div>
              </div>
              <h3>Реквизиты банка</h3>
              <div class="row input-container__column_adaptive-xl">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Полное наименование банка</label>
                    <core-text-input name="company.bank_accounts[0].bank_name" :tooltip='TooltipEnum.BANK_NAME'
                      placeholder="Введите полное наименование банка" :data='data' :showError='true' :errors='errors' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">БИК</label>
                    <core-numeric-input :data='data' name="company.bank_accounts[0].bik" placeholder='044525225'
                      mask="#########" :showError='true' :errors='errors' :tooltip='TooltipEnum.BIK' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Корреспондентский счет</label>
                    <core-numeric-input :data='data' name="company.bank_accounts[0].correspondent_account"
                      placeholder='30101810200000000225' mask="####################" :showError='true' :errors='errors'
                      :tooltip='TooltipEnum.CORRESPONDENT_ACCOUNT' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Расчетный счет</label>
                    <core-numeric-input :data='data' name="company.bank_accounts[0].current_account"
                      placeholder='40702810900000012345' mask="####################" :showError='true' :errors='errors'
                      :tooltip='TooltipEnum.CURRENT_ACCOUNT' />
                  </div>
                </div>
              </div>
              <h3>Договор</h3>
              <div class="row input-container__column_adaptive-md">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Номер договора <span class="text-danger">*</span></label>
                    <core-text-input :data='data' name="company.contracts[0].number" mask='AA######'
                      :stringOptions='agreementNumberOptions' :showError='true' :errors='errors'
                      :tooltip='TooltipEnum.CONTRACT_NUMBER' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Дата начала <span class="text-danger">*</span></label>
                    <core-calendar-input inputFormat='dd-MM-yyyy' name='company.contracts[0].start_date' :data='data'
                      :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedStartDate' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Дата окончания <span class="text-danger">*</span></label>
                    <core-calendar-input inputFormat='dd-MM-yyyy' name='company.contracts[0].end_date' :data='data'
                      :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedEndDate' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='buttons_container'>
            <core-submit-button className="btn btn-primary submit-btn" text='Отправить доступы' :isLoading='isLoading'
              :disabled='isSubmitDisabled' />
            <button type='button' data-bs-toggle="modal" data-bs-target="#warning-alert-modal"
              class="btn btn-outline-primary submit-btn">Сбросить
              данные</button>
          </div>
        </core-form>
      </div>
    </div>
  </div>


  <div class="offcanvas-backdrop fade show" @click='() => onClose(false)'></div>
</template>
<script setup>
import { reactive, onMounted, computed } from 'vue';
import { getEnums } from '@/shared/core-api/getEnums.ts';
import { stringOptions, agreementNumberOptions, okvedOptions } from "@/shared/validation/index.ts";
import { managerSchema, resourseManagerSchema } from "@/shared/validation/addEmployer.ts";
import { COMPANY_TYPE_ENUM, USER_ROLE_ENUM } from "@/shared/validation/enums.ts"
import { useAddEmployerStore } from '@/stores/addEmployer.ts';
import { TooltipEnum } from '@/shared/core-form/index.ts';
const dialogTitle = 'Сбросить данные';
const dialogBody = 'Вы уверены, что хотите очистить все поля?';
const dialogOnSuccessButton = 'Продолжить';
const dialogOnCancelButton = 'Отмена';
const dialogOnSuccess = () => {
  data.$reset();
}

const props = defineProps({
  onClose: Function,
  onModalSuccess: Function,
})

const isResourseRole = computed(() => {
  return data['user.role'] === USER_ROLE_ENUM.RESOURSE_MANAGER
})

const data = useAddEmployerStore()
if (typeof data['company.contracts[0].start_date'] === 'string') {
  data['company.contracts[0].start_date'] = new Date(data['company.contracts[0].start_date'])
}

if (typeof data['company.contracts[0].end_date'] === 'string') {
  data['company.contracts[0].end_date'] = new Date(data['company.contracts[0].end_date'])
}

let roleOptions = reactive([])
let companyOptions = reactive([])


onMounted(async () => {
  try {
    const { user_roles, company_types } = await getEnums(['user_roles', 'company_types'], true);
    user_roles && user_roles.forEach(item => {
      if (item === 'Администратор') {
        return;
      }
      roleOptions.push(item)
    });

    company_types && company_types.forEach(item => {
      companyOptions.push(item)
    })
  } catch (e) {

  }
})

const isIPRole = computed(() => {
  return data['company.type'] === COMPANY_TYPE_ENUM.IP
})

const isSubmitDisabled = computed(() => {
  if (!isResourseRole.value) {
    return !(data["user.name"] && data["user.role"] && data["user.email"])
  } else if (isResourseRole.value) {
    return !(data["user.name"] && data["user.role"] && data["user.email"] && data["company.type"]
      && data["company.short_name"] && data["company.contracts[0].number"] && data["company.contracts[0].start_date"]
      && data["company.contracts[0].end_date"])
  }

  return true
})

const onSuccess = () => {
  const email = data["user.email"];
  data.$reset();
  props.onModalSuccess(email);
}

const radioOptions = [
  {
    label: "С ндс",
    value: 1,
  },
  {
    label: "Без ндс",
    value: 0,
  },
];

</script>
