<template>
  <div class="row filter-row" style="padding-bottom: 1rem">
    <div class="col-sm-12 col-md-9">
      <div class="input-block form-focus select-focus">
        <core-select-input name="competence_tf_id" className="filter-select" :data="filters" :options="techOptions"
          displayName="name" valueProp='id' placeholder="По технической области" :disabled="isDataLoading" />
      </div>
    </div>

    <div class="col-sm-12 col-md-3">
      <button class="btn btn-success w-100 text-capitalize" @click="resetFilters">
        Сбросить фильтры
      </button>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted, ref, watch } from 'vue'
import { getEnums } from '@/shared/core-api/getEnums.ts';
const props = defineProps({
  updateServerOptions: Function,
});
const filters = reactive({
  competence_tf_id: '',
})

const resetFilters = () => {
  filters.competence_tf_id = '';
}

const techOptions = ref([]);
const isDataLoading = ref(false);

const onChange = () => {
  props.updateServerOptions({ competence_tf_id: filters.competence_tf_id })
}

watch(
  filters,
  () => {
    onChange();
  },
  { deep: true }
);

onMounted(async () => {
  try {
    isDataLoading.value = true;
    const { competencies_technical_fields } = await getEnums('competencies_technical_fields', {
      auth: true
    })

    techOptions.value = competencies_technical_fields
    isDataLoading.value = false
  } catch (e) {
    isDataLoading.value = false
  }
})
</script>
